/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 112.0.0-SNAPSHOT
 */

export type BasketSubscriberOrderType =
  (typeof BasketSubscriberOrderType)[keyof typeof BasketSubscriberOrderType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BasketSubscriberOrderType = {
  UPGRADE: "UPGRADE",
  DOWNGRADE: "DOWNGRADE",
  ADD_DATA: "ADD_DATA",
  ADD_WATCH: "ADD_WATCH",
  RENEW: "RENEW",
} as const;
