/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 112.0.0-SNAPSHOT
 */

export type VoiceSettingsReasonItem =
  (typeof VoiceSettingsReasonItem)[keyof typeof VoiceSettingsReasonItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VoiceSettingsReasonItem = {
  BillNotPaid: "BillNotPaid",
  BillDunning: "BillDunning",
  CreditClassC: "CreditClassC",
  NoPaymentMean: "NoPaymentMean",
  NoActiveVoicePackage: "NoActiveVoicePackage",
  CostLimitReached: "CostLimitReached",
} as const;
