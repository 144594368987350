/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Compax Authorization Service
 * API by Compax
 * OpenAPI spec version: 112.0.0-SNAPSHOT
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";

import type { ErrorType } from "@/mutator/authApiInstance";
import { authApiInstance } from "@/mutator/authApiInstance";

import type {
  AuthServerAgentLoginRequest,
  AuthServerChangePasswordRequest,
  AuthServerChangeUsernameRequest,
  AuthServerSetPasswordRequest,
  AuthServerValidateUserTokenRequest,
  CancelSetupResponse,
  ChangeTwoFactorAuthenticationParams,
  ChangeTwoFactorAuthenticationSMSNumberParams,
  CompaxOAuth2AccessTokenModel,
  OAuth2AccessToken,
  OpenIdUserInfo,
  SetUpTwoFactorAuthenticationParams,
  SetUpTwoFactorAuthenticationReponse,
  TwoFactorAuthenticationBackupCodeConfirmation,
  TwoFactorAuthenticationResponse,
  ValidateParams,
  ValidateTwoFactorAuthenticationParams,
  ValidateTwoFactorAuthenticationResponse,
} from "./model";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Authentication for changing 2FA settings
 */
export const authenticate = (
  options?: SecondParameter<typeof authApiInstance>,
) => {
  return authApiInstance<TwoFactorAuthenticationResponse>(
    { url: `/changeTwoFactor/authenticate2faSettings`, method: "POST" },
    options,
  );
};

export const getAuthenticateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authenticate>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authenticate>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authenticate>>,
    void
  > = () => {
    return authenticate(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthenticateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authenticate>>
>;

export type AuthenticateMutationError = ErrorType<unknown>;

/**
 * @summary Authentication for changing 2FA settings
 */
export const useAuthenticate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authenticate>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof authenticate>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getAuthenticateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Change 2FA Method
 */
export const changeTwoFactorAuthentication = (
  params: ChangeTwoFactorAuthenticationParams,
  options?: SecondParameter<typeof authApiInstance>,
) => {
  return authApiInstance<SetUpTwoFactorAuthenticationReponse>(
    { url: `/changeTwoFactor/change`, method: "POST", params },
    options,
  );
};

export const getChangeTwoFactorAuthenticationMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeTwoFactorAuthentication>>,
    TError,
    { params: ChangeTwoFactorAuthenticationParams },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof changeTwoFactorAuthentication>>,
  TError,
  { params: ChangeTwoFactorAuthenticationParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof changeTwoFactorAuthentication>>,
    { params: ChangeTwoFactorAuthenticationParams }
  > = (props) => {
    const { params } = props ?? {};

    return changeTwoFactorAuthentication(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangeTwoFactorAuthenticationMutationResult = NonNullable<
  Awaited<ReturnType<typeof changeTwoFactorAuthentication>>
>;

export type ChangeTwoFactorAuthenticationMutationError = ErrorType<unknown>;

/**
 * @summary Change 2FA Method
 */
export const useChangeTwoFactorAuthentication = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeTwoFactorAuthentication>>,
    TError,
    { params: ChangeTwoFactorAuthenticationParams },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof changeTwoFactorAuthentication>>,
  TError,
  { params: ChangeTwoFactorAuthenticationParams },
  TContext
> => {
  const mutationOptions =
    getChangeTwoFactorAuthenticationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Change phone number for 2FA SMS
 */
export const changeTwoFactorAuthenticationSMSNumber = (
  params: ChangeTwoFactorAuthenticationSMSNumberParams,
  options?: SecondParameter<typeof authApiInstance>,
) => {
  return authApiInstance<SetUpTwoFactorAuthenticationReponse>(
    { url: `/changeTwoFactor/changeNumber`, method: "POST", params },
    options,
  );
};

export const getChangeTwoFactorAuthenticationSMSNumberMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeTwoFactorAuthenticationSMSNumber>>,
    TError,
    { params: ChangeTwoFactorAuthenticationSMSNumberParams },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof changeTwoFactorAuthenticationSMSNumber>>,
  TError,
  { params: ChangeTwoFactorAuthenticationSMSNumberParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof changeTwoFactorAuthenticationSMSNumber>>,
    { params: ChangeTwoFactorAuthenticationSMSNumberParams }
  > = (props) => {
    const { params } = props ?? {};

    return changeTwoFactorAuthenticationSMSNumber(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangeTwoFactorAuthenticationSMSNumberMutationResult = NonNullable<
  Awaited<ReturnType<typeof changeTwoFactorAuthenticationSMSNumber>>
>;

export type ChangeTwoFactorAuthenticationSMSNumberMutationError =
  ErrorType<unknown>;

/**
 * @summary Change phone number for 2FA SMS
 */
export const useChangeTwoFactorAuthenticationSMSNumber = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeTwoFactorAuthenticationSMSNumber>>,
    TError,
    { params: ChangeTwoFactorAuthenticationSMSNumberParams },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof changeTwoFactorAuthenticationSMSNumber>>,
  TError,
  { params: ChangeTwoFactorAuthenticationSMSNumberParams },
  TContext
> => {
  const mutationOptions =
    getChangeTwoFactorAuthenticationSMSNumberMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Display data for current 2FA method
 */
export const displayTwoFactorAuthenticationData = (
  options?: SecondParameter<typeof authApiInstance>,
) => {
  return authApiInstance<SetUpTwoFactorAuthenticationReponse>(
    { url: `/changeTwoFactor/displayTwoFactorData`, method: "POST" },
    options,
  );
};

export const getDisplayTwoFactorAuthenticationDataMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof displayTwoFactorAuthenticationData>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof displayTwoFactorAuthenticationData>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof displayTwoFactorAuthenticationData>>,
    void
  > = () => {
    return displayTwoFactorAuthenticationData(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DisplayTwoFactorAuthenticationDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof displayTwoFactorAuthenticationData>>
>;

export type DisplayTwoFactorAuthenticationDataMutationError =
  ErrorType<unknown>;

/**
 * @summary Display data for current 2FA method
 */
export const useDisplayTwoFactorAuthenticationData = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof displayTwoFactorAuthenticationData>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof displayTwoFactorAuthenticationData>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getDisplayTwoFactorAuthenticationDataMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Validation of the 2FA Secure Zone Token
 */
export const validate = (
  params: ValidateParams,
  options?: SecondParameter<typeof authApiInstance>,
) => {
  return authApiInstance<ValidateTwoFactorAuthenticationResponse>(
    { url: `/changeTwoFactor/validate`, method: "POST", params },
    options,
  );
};

export const getValidateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validate>>,
    TError,
    { params: ValidateParams },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof validate>>,
  TError,
  { params: ValidateParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof validate>>,
    { params: ValidateParams }
  > = (props) => {
    const { params } = props ?? {};

    return validate(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ValidateMutationResult = NonNullable<
  Awaited<ReturnType<typeof validate>>
>;

export type ValidateMutationError = ErrorType<unknown>;

/**
 * @summary Validation of the 2FA Secure Zone Token
 */
export const useValidate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validate>>,
    TError,
    { params: ValidateParams },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof validate>>,
  TError,
  { params: ValidateParams },
  TContext
> => {
  const mutationOptions = getValidateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Cancel 2FA activation process
 */
export const cancelSetup = (
  options?: SecondParameter<typeof authApiInstance>,
) => {
  return authApiInstance<CancelSetupResponse>(
    { url: `/twoFactor/cancelSetUp`, method: "POST" },
    options,
  );
};

export const getCancelSetupMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelSetup>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof cancelSetup>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelSetup>>,
    void
  > = () => {
    return cancelSetup(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelSetupMutationResult = NonNullable<
  Awaited<ReturnType<typeof cancelSetup>>
>;

export type CancelSetupMutationError = ErrorType<unknown>;

/**
 * @summary Cancel 2FA activation process
 */
export const useCancelSetup = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelSetup>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof cancelSetup>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getCancelSetupMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Confirm receipt of the backup codes
 */
export const confirmTwoFactorAuthenticationBackupCodes = (
  options?: SecondParameter<typeof authApiInstance>,
) => {
  return authApiInstance<TwoFactorAuthenticationBackupCodeConfirmation>(
    { url: `/twoFactor/confirmBackupCodes`, method: "POST" },
    options,
  );
};

export const getConfirmTwoFactorAuthenticationBackupCodesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmTwoFactorAuthenticationBackupCodes>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof confirmTwoFactorAuthenticationBackupCodes>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof confirmTwoFactorAuthenticationBackupCodes>>,
    void
  > = () => {
    return confirmTwoFactorAuthenticationBackupCodes(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfirmTwoFactorAuthenticationBackupCodesMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof confirmTwoFactorAuthenticationBackupCodes>>
  >;

export type ConfirmTwoFactorAuthenticationBackupCodesMutationError =
  ErrorType<unknown>;

/**
 * @summary Confirm receipt of the backup codes
 */
export const useConfirmTwoFactorAuthenticationBackupCodes = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmTwoFactorAuthenticationBackupCodes>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof confirmTwoFactorAuthenticationBackupCodes>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getConfirmTwoFactorAuthenticationBackupCodesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Set up 2FA - SMS/APP
 */
export const setUpTwoFactorAuthentication = (
  params: SetUpTwoFactorAuthenticationParams,
  options?: SecondParameter<typeof authApiInstance>,
) => {
  return authApiInstance<SetUpTwoFactorAuthenticationReponse>(
    { url: `/twoFactor/setUp`, method: "POST", params },
    options,
  );
};

export const getSetUpTwoFactorAuthenticationMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setUpTwoFactorAuthentication>>,
    TError,
    { params: SetUpTwoFactorAuthenticationParams },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setUpTwoFactorAuthentication>>,
  TError,
  { params: SetUpTwoFactorAuthenticationParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setUpTwoFactorAuthentication>>,
    { params: SetUpTwoFactorAuthenticationParams }
  > = (props) => {
    const { params } = props ?? {};

    return setUpTwoFactorAuthentication(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetUpTwoFactorAuthenticationMutationResult = NonNullable<
  Awaited<ReturnType<typeof setUpTwoFactorAuthentication>>
>;

export type SetUpTwoFactorAuthenticationMutationError = ErrorType<unknown>;

/**
 * @summary Set up 2FA - SMS/APP
 */
export const useSetUpTwoFactorAuthentication = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setUpTwoFactorAuthentication>>,
    TError,
    { params: SetUpTwoFactorAuthenticationParams },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setUpTwoFactorAuthentication>>,
  TError,
  { params: SetUpTwoFactorAuthenticationParams },
  TContext
> => {
  const mutationOptions =
    getSetUpTwoFactorAuthenticationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Validate given code
 */
export const validateTwoFactorAuthentication = (
  params: ValidateTwoFactorAuthenticationParams,
  options?: SecondParameter<typeof authApiInstance>,
) => {
  return authApiInstance<ValidateTwoFactorAuthenticationResponse>(
    { url: `/twoFactor/validate`, method: "POST", params },
    options,
  );
};

export const getValidateTwoFactorAuthenticationMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateTwoFactorAuthentication>>,
    TError,
    { params: ValidateTwoFactorAuthenticationParams },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof validateTwoFactorAuthentication>>,
  TError,
  { params: ValidateTwoFactorAuthenticationParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof validateTwoFactorAuthentication>>,
    { params: ValidateTwoFactorAuthenticationParams }
  > = (props) => {
    const { params } = props ?? {};

    return validateTwoFactorAuthentication(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ValidateTwoFactorAuthenticationMutationResult = NonNullable<
  Awaited<ReturnType<typeof validateTwoFactorAuthentication>>
>;

export type ValidateTwoFactorAuthenticationMutationError = ErrorType<unknown>;

/**
 * @summary Validate given code
 */
export const useValidateTwoFactorAuthentication = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateTwoFactorAuthentication>>,
    TError,
    { params: ValidateTwoFactorAuthenticationParams },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof validateTwoFactorAuthentication>>,
  TError,
  { params: ValidateTwoFactorAuthenticationParams },
  TContext
> => {
  const mutationOptions =
    getValidateTwoFactorAuthenticationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get userinfo
 */
export const getOpenIdUserInfo = (
  options?: SecondParameter<typeof authApiInstance>,
) => {
  return authApiInstance<OpenIdUserInfo>(
    { url: `/user`, method: "GET" },
    options,
  );
};

export const getGetOpenIdUserInfoQueryKey = () => {
  return [`/user`] as const;
};

export const getGetOpenIdUserInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getOpenIdUserInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getOpenIdUserInfo>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof authApiInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOpenIdUserInfoQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getOpenIdUserInfo>>
  > = () => getOpenIdUserInfo(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOpenIdUserInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOpenIdUserInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOpenIdUserInfo>>
>;
export type GetOpenIdUserInfoQueryError = ErrorType<unknown>;

/**
 * @summary Get userinfo
 */
export const useGetOpenIdUserInfo = <
  TData = Awaited<ReturnType<typeof getOpenIdUserInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getOpenIdUserInfo>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOpenIdUserInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get userinfo
 */
export const getOpenIdUserInfo1 = (
  options?: SecondParameter<typeof authApiInstance>,
) => {
  return authApiInstance<OpenIdUserInfo>(
    { url: `/user`, method: "POST" },
    options,
  );
};

export const getGetOpenIdUserInfo1MutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getOpenIdUserInfo1>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getOpenIdUserInfo1>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getOpenIdUserInfo1>>,
    void
  > = () => {
    return getOpenIdUserInfo1(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetOpenIdUserInfo1MutationResult = NonNullable<
  Awaited<ReturnType<typeof getOpenIdUserInfo1>>
>;

export type GetOpenIdUserInfo1MutationError = ErrorType<unknown>;

/**
 * @summary Get userinfo
 */
export const useGetOpenIdUserInfo1 = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getOpenIdUserInfo1>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getOpenIdUserInfo1>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getGetOpenIdUserInfo1MutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Agent login
 */
export const agentlogin = (
  authServerAgentLoginRequest: AuthServerAgentLoginRequest,
  options?: SecondParameter<typeof authApiInstance>,
) => {
  return authApiInstance<CompaxOAuth2AccessTokenModel>(
    {
      url: `/user/agentlogin`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: authServerAgentLoginRequest,
    },
    options,
  );
};

export const getAgentloginMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof agentlogin>>,
    TError,
    { data: AuthServerAgentLoginRequest },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof agentlogin>>,
  TError,
  { data: AuthServerAgentLoginRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof agentlogin>>,
    { data: AuthServerAgentLoginRequest }
  > = (props) => {
    const { data } = props ?? {};

    return agentlogin(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AgentloginMutationResult = NonNullable<
  Awaited<ReturnType<typeof agentlogin>>
>;
export type AgentloginMutationBody = AuthServerAgentLoginRequest;
export type AgentloginMutationError = ErrorType<unknown>;

/**
 * @summary Agent login
 */
export const useAgentlogin = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof agentlogin>>,
    TError,
    { data: AuthServerAgentLoginRequest },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof agentlogin>>,
  TError,
  { data: AuthServerAgentLoginRequest },
  TContext
> => {
  const mutationOptions = getAgentloginMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Change password
 */
export const changePassword = (
  authServerChangePasswordRequest: AuthServerChangePasswordRequest,
  options?: SecondParameter<typeof authApiInstance>,
) => {
  return authApiInstance<OAuth2AccessToken>(
    {
      url: `/user/change-password`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: authServerChangePasswordRequest,
    },
    options,
  );
};

export const getChangePasswordMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changePassword>>,
    TError,
    { data: AuthServerChangePasswordRequest },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof changePassword>>,
  TError,
  { data: AuthServerChangePasswordRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof changePassword>>,
    { data: AuthServerChangePasswordRequest }
  > = (props) => {
    const { data } = props ?? {};

    return changePassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangePasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof changePassword>>
>;
export type ChangePasswordMutationBody = AuthServerChangePasswordRequest;
export type ChangePasswordMutationError = ErrorType<unknown>;

/**
 * @summary Change password
 */
export const useChangePassword = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changePassword>>,
    TError,
    { data: AuthServerChangePasswordRequest },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof changePassword>>,
  TError,
  { data: AuthServerChangePasswordRequest },
  TContext
> => {
  const mutationOptions = getChangePasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Change username for Login
 */
export const changeUsername = (
  authServerChangeUsernameRequest: AuthServerChangeUsernameRequest,
  options?: SecondParameter<typeof authApiInstance>,
) => {
  return authApiInstance<OAuth2AccessToken>(
    {
      url: `/user/change-username`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: authServerChangeUsernameRequest,
    },
    options,
  );
};

export const getChangeUsernameMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeUsername>>,
    TError,
    { data: AuthServerChangeUsernameRequest },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof changeUsername>>,
  TError,
  { data: AuthServerChangeUsernameRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof changeUsername>>,
    { data: AuthServerChangeUsernameRequest }
  > = (props) => {
    const { data } = props ?? {};

    return changeUsername(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangeUsernameMutationResult = NonNullable<
  Awaited<ReturnType<typeof changeUsername>>
>;
export type ChangeUsernameMutationBody = AuthServerChangeUsernameRequest;
export type ChangeUsernameMutationError = ErrorType<unknown>;

/**
 * @summary Change username for Login
 */
export const useChangeUsername = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeUsername>>,
    TError,
    { data: AuthServerChangeUsernameRequest },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof changeUsername>>,
  TError,
  { data: AuthServerChangeUsernameRequest },
  TContext
> => {
  const mutationOptions = getChangeUsernameMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Logout
 */
export const logout = (options?: SecondParameter<typeof authApiInstance>) => {
  return authApiInstance<void>(
    { url: `/user/logout`, method: "POST" },
    options,
  );
};

export const getLogoutMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof logout>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof logout>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof logout>>,
    void
  > = () => {
    return logout(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LogoutMutationResult = NonNullable<
  Awaited<ReturnType<typeof logout>>
>;

export type LogoutMutationError = ErrorType<unknown>;

/**
 * @summary Logout
 */
export const useLogout = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof logout>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof logout>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getLogoutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Set password
 */
export const setPassword = (
  authServerSetPasswordRequest: AuthServerSetPasswordRequest,
  options?: SecondParameter<typeof authApiInstance>,
) => {
  return authApiInstance<void>(
    {
      url: `/user/set-password`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: authServerSetPasswordRequest,
    },
    options,
  );
};

export const getSetPasswordMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setPassword>>,
    TError,
    { data: AuthServerSetPasswordRequest },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setPassword>>,
  TError,
  { data: AuthServerSetPasswordRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setPassword>>,
    { data: AuthServerSetPasswordRequest }
  > = (props) => {
    const { data } = props ?? {};

    return setPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof setPassword>>
>;
export type SetPasswordMutationBody = AuthServerSetPasswordRequest;
export type SetPasswordMutationError = ErrorType<unknown>;

/**
 * @summary Set password
 */
export const useSetPassword = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setPassword>>,
    TError,
    { data: AuthServerSetPasswordRequest },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setPassword>>,
  TError,
  { data: AuthServerSetPasswordRequest },
  TContext
> => {
  const mutationOptions = getSetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary validate user token
 */
export const validateUserToken = (
  authServerValidateUserTokenRequest: AuthServerValidateUserTokenRequest,
  options?: SecondParameter<typeof authApiInstance>,
) => {
  return authApiInstance<void>(
    {
      url: `/user/user-token`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: authServerValidateUserTokenRequest,
    },
    options,
  );
};

export const getValidateUserTokenMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateUserToken>>,
    TError,
    { data: AuthServerValidateUserTokenRequest },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof validateUserToken>>,
  TError,
  { data: AuthServerValidateUserTokenRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof validateUserToken>>,
    { data: AuthServerValidateUserTokenRequest }
  > = (props) => {
    const { data } = props ?? {};

    return validateUserToken(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ValidateUserTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof validateUserToken>>
>;
export type ValidateUserTokenMutationBody = AuthServerValidateUserTokenRequest;
export type ValidateUserTokenMutationError = ErrorType<unknown>;

/**
 * @summary validate user token
 */
export const useValidateUserToken = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateUserToken>>,
    TError,
    { data: AuthServerValidateUserTokenRequest },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof validateUserToken>>,
  TError,
  { data: AuthServerValidateUserTokenRequest },
  TContext
> => {
  const mutationOptions = getValidateUserTokenMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Check if user exists
 */
export const userExists = (
  username: string,
  options?: SecondParameter<typeof authApiInstance>,
) => {
  return authApiInstance<void>(
    { url: `/user/${username}`, method: "HEAD" },
    options,
  );
};

export const getUserExistsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userExists>>,
    TError,
    { username: string },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userExists>>,
  TError,
  { username: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userExists>>,
    { username: string }
  > = (props) => {
    const { username } = props ?? {};

    return userExists(username, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserExistsMutationResult = NonNullable<
  Awaited<ReturnType<typeof userExists>>
>;

export type UserExistsMutationError = ErrorType<unknown>;

/**
 * @summary Check if user exists
 */
export const useUserExists = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userExists>>,
    TError,
    { username: string },
    TContext
  >;
  request?: SecondParameter<typeof authApiInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userExists>>,
  TError,
  { username: string },
  TContext
> => {
  const mutationOptions = getUserExistsMutationOptions(options);

  return useMutation(mutationOptions);
};
