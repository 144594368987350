import "./FormField.scss";

import clsx from "clsx";
import React, { FocusEventHandler, useState } from "react";
import {
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ErrorMessage } from "@/components/Interface/FormFields/ErrorMessage/ErrorMessage";
import { InputLabel } from "@/components/Interface/FormFields/InputLabel/InputLabel";

type ErrorMessageProp = {
  // Key can only be a string that ends with "Error"
  [K in `${string}Error`]: string;
};

type CustomRegisterOptions = RegisterOptions &
  ErrorMessageProp & {
    readOnly?: boolean | undefined;
  };

export interface FormFieldProps<TFormValues extends FieldValues> {
  id: string;
  name: Path<TFormValues>;
  label?: string;
  ariaLabel?: string;
  options?: CustomRegisterOptions;
  showRequiredIndicator?: boolean;
  errors: FieldErrors;
  register: UseFormRegister<TFormValues>;
  type?: HTMLInputElement["type"];
  autocomplete?: string;
  placeholder?: string;
  onFocus?: FocusEventHandler<HTMLInputElement> | undefined;
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
  value?: string | number;
  maxLength?: number;
  passwordRules?: string;
  icon?: JSX.Element;
  className?: string;
}

export const FormField = <TFormValues extends FieldValues>({
  id,
  name,
  label,
  ariaLabel,
  options = {
    required: true,
  },
  showRequiredIndicator = false,
  errors,
  register,
  type = "text",
  autocomplete,
  placeholder = "",
  onFocus,
  children,
  value,
  icon,
  passwordRules,
  className,
}: React.PropsWithChildren<FormFieldProps<TFormValues>>) => {
  const { t } = useTranslation();
  const errorType = errors[name]?.type;
  const optionsWithParsedMaxLength = {
    ...options,
    maxLength:
      // if only the number of max allowed characters was given, we add a default error message
      typeof options?.maxLength === "number"
        ? {
            value: options.maxLength,
            message: t("label.validation.maxLength", {
              replace: {
                max: options.maxLength,
              },
            }),
          }
        : options?.maxLength,
  };

  // We aim to utilize the message available in the options object to enable dynamic translation
  // when there is a change in the error.
  // Given the various checks we perform, we can confirm that we have a valid error message.
  // If necessary, we can fall back on the actual message provided in the errors object.
  const error =
    errorType &&
    typeof errorType === "string" &&
    errorType in optionsWithParsedMaxLength &&
    // @ts-ignore
    optionsWithParsedMaxLength[errorType];

  let errorMessage = "";
  // This is for cases when the error is a string, e.g. when setting "require: t("my.error")" in the options.
  if (typeof error === "string") {
    errorMessage = error;
  } else if (typeof error === "object" && typeof error.message === "string") {
    errorMessage = error.message;
  } else if (typeof errors[name]?.message === "string") {
    errorMessage = errors[name]?.message as string;
  }

  return (
    <div
      className={clsx("form-field-container", className, !!icon && "withIcon")}
    >
      {label && (
        <div className="label-container">
          <InputLabel
            error={errors[name] !== undefined}
            htmlFor={id}
            label={
              label +
              (showRequiredIndicator &&
              options?.required &&
              !options?.readOnly &&
              !options?.disabled
                ? " *"
                : "")
            }
            disabled={!!options?.disabled || !!options.readOnly}
          />
          {children}
        </div>
      )}

      <div className="relative">
        <input
          id={id}
          aria-label={label || ariaLabel}
          aria-required={!!options?.required}
          aria-invalid={errors[name] ? "true" : "false"}
          autoComplete={autocomplete}
          {...register(
            name,
            optionsWithParsedMaxLength as RegisterOptions<TFormValues>,
          )}
          {...(options.readOnly && { readOnly: true })}
          {...(type && { type })}
          {...(value && { value })}
          {...(passwordRules && { passwordrules: passwordRules })}
          data-testid={name}
          placeholder={placeholder}
          {...(onFocus && {
            onFocus,
            // @ts-ignore Type mismatch between Touch and Focus Event.
            onTouchStart: (event) => onFocus(event),
          })}
          className={`${errors[name] ? "error" : ""} min-w-max`}
        />
        {icon && <div className="icon">{icon}</div>}
      </div>

      {errorMessage && <ErrorMessage message={errorMessage} name={name} />}
    </div>
  );
};

export interface FormFieldWithHintProps<TFormValues extends FieldValues>
  extends FormFieldProps<TFormValues> {
  hint?: string;
}

export const FormFieldWithHint = <TFormValues extends object>(
  props: React.PropsWithChildren<FormFieldWithHintProps<TFormValues>>,
) => {
  const [selected, setSelected] = useState(false);

  return (
    <div className="form-field-container">
      <FormField
        {...props}
        options={{
          ...props.options,
          onBlur: props.options?.readOnly
            ? undefined
            : () => setSelected(false),
        }}
        // Disabled fields should not be focusable
        {...(props.options?.readOnly
          ? undefined
          : { onFocus: () => setSelected(true) })}
      >
        {props.children}
      </FormField>

      {props.hint && selected && !props.errors[props.name]?.message && (
        <span className="hint">{props.hint}</span>
      )}
    </div>
  );
};
