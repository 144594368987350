import { useGetCustomer } from "@/services/api";
import { BasicCustomerDataTwoFactorMethod } from "@/services/model";

import { useNeedsIDCheck } from "./useNeedsIDCheck";

export const useHasTwoFactorAuth: () => boolean | undefined = () => {
  const { data: customer } = useGetCustomer();
  const needsIdCheck = useNeedsIDCheck();
  const shouldNotSee2FA =
    customer?.twoFactorMethod !== BasicCustomerDataTwoFactorMethod.STANDARD ||
    needsIdCheck;

  return shouldNotSee2FA;
};
