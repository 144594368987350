import { useQueryClient } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button, SubmitButton } from "@/components/Interface/Button/Button";
import {
  FormField,
  FormFieldWithHint,
} from "@/components/Interface/FormFields/FormField/FormField";
import { useAlert } from "@/hooks/useAlert";
import { useUpdateAccountContact } from "@/services/api";
import { UpdateAccountResponse } from "@/services/model";
import { AlertTypes, userAccountStateAtom } from "@/utils/atoms";
import { log } from "@/utils/log";

type BillingInfoFormFields = {
  emailAddress: string;
  reference: string;
  note: string;
};

export const BillingInfoForm: FC<{
  billingInfo: UpdateAccountResponse;
}> = ({ billingInfo }) => {
  const {
    register,
    reset,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<BillingInfoFormFields>({ defaultValues: billingInfo });
  const { t } = useTranslation();
  const showAlert = useAlert();
  const queryClient = useQueryClient();
  const setUserAccountState = useSetAtom(userAccountStateAtom);
  const { mutate: updateAccountContact, isLoading } = useUpdateAccountContact({
    mutation: {
      onSuccess: (data) => {
        showAlert({
          type: AlertTypes.success,
          text: t("portal:user-account.paymentMethods.billingForm.success"),
        });
        queryClient.invalidateQueries(["/account/billingInfo"]).then(() =>
          setUserAccountState((prev) => ({
            ...prev,
            editingBillingInfo: false,
          })),
        );
      },
      onError: (error) => {
        if (`${error?.response?.data}`.includes("Invalid Email Address")) {
          setError("emailAddress", {
            type: "backendError",
          });
        }
        showAlert({
          type: AlertTypes.error,
          text: t("portal:user-account.paymentMethods.billingForm.error"),
        });
        log("Error in customer form: " + error);
      },
    },
  });

  const setEditingBillingInfo = (isEditing: boolean) => {
    setUserAccountState((prev) => ({
      ...prev,
      editingBillingInfo: isEditing,
    }));
  };
  const onSubmit = (data: BillingInfoFormFields) => {
    updateAccountContact({ data });
  };

  return (
    <>
      <form data-testid="billing-info-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6">
          <FormFieldWithHint<BillingInfoFormFields>
            id="billing-form-email-input"
            label={t(
              "portal:user-account.paymentMethods.billingForm.emailAddress.label",
            )}
            placeholder={t(
              "portal:user-account.paymentMethods.billingForm.emailAddress.placeholder",
            )}
            hint={t(
              "portal:user-account.paymentMethods.billingForm.emailAddress.hint",
            )}
            errors={errors}
            register={register}
            name={"emailAddress"}
            options={{
              backendError: t("label.validation.email"),
              disabled: isLoading,
            }}
          />
        </div>
        <div className="mb-6">
          <FormFieldWithHint<BillingInfoFormFields>
            id="billing-form-reference-input"
            label={t(
              "portal:user-account.paymentMethods.billingForm.reference.label",
            )}
            placeholder={t(
              "portal:user-account.paymentMethods.billingForm.reference.placeholder",
            )}
            hint={t(
              "portal:user-account.paymentMethods.billingForm.reference.hint",
            )}
            errors={errors}
            register={register}
            name={"reference"}
            options={{ required: false, maxLength: 250, disabled: isLoading }}
          />
        </div>
        <FormField<BillingInfoFormFields>
          id="billing-form-note-input"
          label={t("portal:user-account.paymentMethods.billingForm.note.label")}
          placeholder={t(
            "portal:user-account.paymentMethods.billingForm.note.placeholder",
          )}
          errors={errors}
          register={register}
          name={"note"}
          options={{ required: false, maxLength: 250, disabled: isLoading }}
        />
        <div className="flex flex-col gap-4 mt-10 md:flex-row justify-between md:justify-end md:col-span-2">
          <Button
            onClick={() => {
              reset();
              setEditingBillingInfo(false);
            }}
            className="accent w-full inverted"
          >
            {t("common.buttons.cancel")}
          </Button>
          <SubmitButton
            className="w-full accent"
            label={t("common.buttons.save")}
            disabled={isLoading}
          />
        </div>
      </form>
    </>
  );
};
