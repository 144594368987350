import "./App.scss";

import { Outlet } from "react-router-dom";

import { SplashScreens } from "@/components/Layout/SplashScreens/SplashScreens";
import { FullScreenLoadingProvider } from "@/provider/FullScreenLoadingProvider/FullScreenLoadingProvider";

export const App = () => (
  <FullScreenLoadingProvider>
    <SplashScreens />
    <div className="flex min-h-screen flex-col">
      <div data-testid="app" id="app">
        <Outlet />
      </div>
    </div>
  </FullScreenLoadingProvider>
);
