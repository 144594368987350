import { AxiosError } from "axios";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { StyledLink, SubmitButton } from "@/components/Interface/Button/Button";
import { ConfirmationButtonFlexBox } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { errorShouldBeRetried } from "@/components/Portal/UserAccount/Security/utils";
import { NameConfirmationField } from "@/components/Welcome/AuthenticatorSetup/NameConfirmationField";
import { useAlert } from "@/hooks/useAlert";
import { portalRoutes } from "@/routes/portalRoutes";
import {
  useCancelSetup,
  useChangeTwoFactorAuthentication,
} from "@/services/auth";
import { ChangeTwoFactorAuthenticationMethod } from "@/services/model";
import { AlertTypes } from "@/utils/atoms";

type ConfirmStandardForm = {
  name: string;
};
export const TwoFactorConfirmStandard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ConfirmStandardForm>({
    mode: "onChange",
  });

  const { mutateAsync: cancelSetup, isLoading: isCancelingSetup } =
    useCancelSetup();
  const { mutateAsync: changeTwoFactorAuth } =
    useChangeTwoFactorAuthentication();
  const showToast = useAlert();

  const isSubmitting = useRef(false);

  const onSubmit = () => {
    if (isSubmitting.current) return;
    isSubmitting.current = true;

    const handleSuccess = () => {
      showToast({
        text: t("portal:user-account.security.2fa.confirmStandard.success"),
        type: AlertTypes.success,
      });
      navigate(portalRoutes.cockpit.base);
    };

    const tryToChange = () =>
      changeTwoFactorAuth({
        params: { method: ChangeTwoFactorAuthenticationMethod.STANDARD },
      });

    tryToChange()
      .then(handleSuccess)
      .catch((error: AxiosError<any>) => {
        if (errorShouldBeRetried(error)) {
          cancelSetup().then(() => {
            setTimeout(() => tryToChange().then(handleSuccess), 5000);
          });
        } else {
          showToast({
            text: t("portal:user-account.security.2fa.confirmStandard.error"),
            type: AlertTypes.error,
          });
        }
      })
      .finally(() => {
        isSubmitting.current = false;
      });
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-6 max-w-[560px] text-black"
    >
      <h3 className="text-secondary-100">
        {t("portal:user-account.security.2fa.initialSetup.title")}
      </h3>
      <div className="flex flex-col gap-4">
        <p className="text-lg font-semibold">
          <span>
            {t("portal:user-account.security.2fa.confirmStandard.subtitle")}
          </span>
          <span className="ml-2 text-primary-100">
            {t("translation:2fa.setup.options.not-recommended")}
          </span>
        </p>
        <p>{t("portal:user-account.security.2fa.confirmStandard.text1")}</p>
        <p>{t("portal:user-account.security.2fa.confirmStandard.text2")}</p>
        <p>{t("portal:user-account.security.2fa.confirmStandard.text3")}</p>
      </div>

      <NameConfirmationField register={register} errors={errors} />
      <ConfirmationButtonFlexBox forceButtonWidth>
        <StyledLink
          to={portalRoutes.user.security.base}
          className="accent inverted"
        >
          {t("portal:common.buttons.back")}
        </StyledLink>
        <SubmitButton
          disabled={isCancelingSetup}
          className="accent"
          label={t("portal:common.buttons.confirm")}
        />
        {isCancelingSetup && <LoadingSpinner />}
      </ConfirmationButtonFlexBox>
    </form>
  );
};
