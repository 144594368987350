import "./DueNow.scss";

import { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";

import { getCurrency } from "@/utils/translationHelpers";

export type DueNowProps = {
  totalOnce: number;
  currency: string;
  dataRateName?: string | ReactNode;
  dataRatePrice?: number;
  billingPeriod: string;
  voiceOptionName?: string | ReactNode;
  voiceOptionPrice?: number;
  watchSimName?: string;
  watchSimPrice?: number;
  credit?: string;
  creditTotal?: number;
  icon?: ReactNode;
};

// TODO - Refactor the RoamingDueNow DeviceTile so they make more sense,
// e.g this component should not be called interface if it's not an interface
// and the DueNowLightInterface should be reused and renamed
export const DueNow = ({
  totalOnce,
  currency,
  billingPeriod,
  dataRateName,
  dataRatePrice,
  voiceOptionName,
  voiceOptionPrice,
  watchSimName,
  watchSimPrice,
  credit,
  creditTotal,
  icon,
}: DueNowProps) => {
  const { t } = useTranslation();

  return (
    <div className="payment-box-wrapper">
      <div className="title-icon">
        <span>
          <Trans
            components={[<span key="dueNow" className="text-primary-100" />]}
            t={t}
          >
            Onboarding.sections.checkout.summary.payOnce
          </Trans>
        </span>
        <div id="device-icon">{icon}</div>
      </div>

      <div className="payment-breakdown">
        {!!dataRateName &&
          dataRatePrice !== undefined &&
          dataRatePrice >= 0 && (
            <>
              <div className="flat-name">
                {dataRateName}
                <p className="mobile-days-label">{billingPeriod}</p>
              </div>
              <span className="days-label">{billingPeriod}</span>
              <span className="price" data-testid="flat-price">
                {getCurrency(dataRatePrice, currency)}
              </span>
            </>
          )}
        {!!voiceOptionName &&
          voiceOptionPrice !== undefined &&
          voiceOptionPrice >= 0 && (
            <>
              <div className="flat-name">
                {voiceOptionName}
                <p className="mobile-days-label">{billingPeriod}</p>
              </div>
              <span className="days-label">{billingPeriod}</span>
              <span className="price" data-testid="voice-price">
                {getCurrency(voiceOptionPrice, currency)}
              </span>
            </>
          )}
        {!!watchSimName &&
          watchSimPrice !== undefined &&
          watchSimPrice >= 0 && (
            <>
              <div className="flat-name">
                {watchSimName}
                <p className="mobile-days-label">{billingPeriod}</p>
              </div>
              <span className="days-label">{billingPeriod}</span>
              <span className="price" data-testid="watch-price">
                {getCurrency(watchSimPrice, currency)}
              </span>
            </>
          )}
      </div>
      <hr className="line" />
      <div className="total-breakdown">
        {creditTotal !== undefined && creditTotal > 0 && (
          <>
            <span className="credit">
              {t("Onboarding.sections.checkout.summary.credit")}{" "}
              <span className="text-[13px] text-black leading-6">{credit}</span>
            </span>
            <span className="credit-amount">
              {getCurrency(creditTotal, currency)}
            </span>
          </>
        )}
        <div className="total">
          {t("Onboarding.sections.checkout.summary.total")}
          <span className="tax-hint font-regular">
            {t("Onboarding.sections.checkout.summary.taxIncluded")}
          </span>
        </div>
        <div className="total-amount" data-testid="total-amount-due-now">
          {totalOnce >= 0 && getCurrency(totalOnce, currency)}
        </div>
      </div>
    </div>
  );
};
