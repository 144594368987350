/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 112.0.0-SNAPSHOT
 */

export type SimCardDetailNetworkResetNotAllowedReasonsItem =
  (typeof SimCardDetailNetworkResetNotAllowedReasonsItem)[keyof typeof SimCardDetailNetworkResetNotAllowedReasonsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SimCardDetailNetworkResetNotAllowedReasonsItem = {
  SIM_LOCKED: "SIM_LOCKED",
  SIM_CANCELLED: "SIM_CANCELLED",
  SIM_TERMINATED: "SIM_TERMINATED",
  SIM_INACTIVE: "SIM_INACTIVE",
  OPEN_ONP: "OPEN_ONP",
  OPEN_ORDER: "OPEN_ORDER",
  OPEN_SPEED_AND_LOCK: "OPEN_SPEED_AND_LOCK",
  OPEN_NETWORK_RESET: "OPEN_NETWORK_RESET",
} as const;
