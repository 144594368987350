import "./NumberPortingChoice.scss";

import clsx from "clsx";
import { useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { Accordion } from "@/components/Interface/Accordion/Accordion";
import { RadioButton } from "@/components/Interface/FormFields/RadioButton/RadioButton";
import { RateFormProps } from "@/components/Onboarding/Steps/RateSelection/RateSelection";

export const NumberPortingChoice = ({
  voiceIsSelected,
}: {
  voiceIsSelected: boolean;
}) => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    register,
  } = useFormContext<RateFormProps>();

  return (
    <fieldset
      id="numberporting-opacity-controller"
      className={clsx(voiceIsSelected || "disabled")}
    >
      <Accordion
        label={t("Onboarding.sections.rate-selection.numberPorting")}
        className="mt-8"
      >
        <div className="number-porting-container">
          <legend className="intro-text">
            {t("translation:Onboarding.sections.rate-selection.takeYourNumber")}
            <p className="hint">
              <b>{t("Onboarding.sections.rate-selection.hint")}</b>
              <Trans
                components={[<b key="key" />]}
                i18nKey="Onboarding.sections.rate-selection.numberPortingHint"
              />
            </p>
          </legend>
          {/* layout for radio buttons and labels */}
          <div className="number-porting-choices">
            <div className="radio-buttons">
              <RadioButton<RateFormProps>
                name="numberPorting"
                value="yes"
                labelComponent={
                  <Trans i18nKey="Onboarding.sections.rate-selection.yesTakeNumber" />
                }
                options={{ disabled: !voiceIsSelected }}
                errors={errors}
                register={register}
              />
            </div>
            <div className="radio-buttons">
              <RadioButton<RateFormProps>
                name="numberPorting"
                value="no"
                labelComponent={
                  <Trans i18nKey="Onboarding.sections.rate-selection.dontTakeNumber" />
                }
                options={{ disabled: !voiceIsSelected }}
                errors={errors}
                register={register}
              />
            </div>
          </div>
        </div>
      </Accordion>
    </fieldset>
  );
};
