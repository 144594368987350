import { FC } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";

import { App } from "@/App";
import { onboardingRoutes as onboardingRouteComponents } from "@/router/OnboardingRoutes";
import { portalRouter as portalRouteComponents } from "@/router/PortalRoutes";
import { PORTAL_BASE_ROUTE } from "@/routes/portalRoutes";
import { NotFound } from "@/views/NotFound/NotFound";
import { Onboarding } from "@/views/Onboarding/Onboarding";
import { Portal } from "@/views/Portal/Portal";
import { Welcome } from "@/views/Welcome";
import { WelcomeEsim } from "@/views/WelcomeEsim";

import { AgentLogin } from "./AgentLogin";
import { authenticatorRouteComponents } from "./AuthenticatorRoutes";
import { ProtectedRoute } from "./ProtectedRoute";
import redirects from "./redirects";

export const Routing: FC = () => (
  <HashRouter>
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <App />
          </ProtectedRoute>
        }
      >
        <Route index element={<Navigate to="/onboarding/1" replace />} />
        <Route path="onboarding/*" element={<Onboarding />}>
          {onboardingRouteComponents}
        </Route>
        <Route path={PORTAL_BASE_ROUTE} element={<Portal />}>
          {portalRouteComponents}
        </Route>
      </Route>
      {authenticatorRouteComponents}
      <Route path="welcome" element={<Welcome />} />
      <Route path="welcome-esim" element={<WelcomeEsim />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="/agentLogin" element={<AgentLogin />} />

      {/* Generate redirects from redirects array */}
      {redirects.map((redirect) => (
        <Route
          key={redirect.from}
          path={redirect.from}
          element={<Navigate to={redirect.to} replace />}
        />
      ))}
    </Routes>
  </HashRouter>
);
