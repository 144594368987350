/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 112.0.0-SNAPSHOT
 */

export type SimCustomerInfoAddESIMNotAllowedReasonItem =
  (typeof SimCustomerInfoAddESIMNotAllowedReasonItem)[keyof typeof SimCustomerInfoAddESIMNotAllowedReasonItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SimCustomerInfoAddESIMNotAllowedReasonItem = {
  TooManySIMs: "TooManySIMs",
  IDCheckOpen: "IDCheckOpen",
  DunningLevel3: "DunningLevel3",
} as const;
