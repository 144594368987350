/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 112.0.0-SNAPSHOT
 */

export type PermissionPermissionType =
  (typeof PermissionPermissionType)[keyof typeof PermissionPermissionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionPermissionType = {
  TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
  LETTER: "LETTER",
  EMAIL: "EMAIL",
  SMS: "SMS",
  PHONE: "PHONE",
  MARKETING: "MARKETING",
  NEWSLETTER: "NEWSLETTER",
} as const;
