import "./Accordion.scss";

import { Disclosure, Transition } from "@headlessui/react";
import { FCC } from "types";

import { ArrowUp } from "@/assets/icons/icons";

interface Props {
  label: string;
  className?: string;
  canOpen?: boolean;
}

export const Accordion: FCC<Props> = ({
  label,
  className,
  canOpen = true,
  children,
}) => {
  return (
    <div className={className}>
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <>
            <div data-testid="accordion-header" className="accordion-header">
              <h6 className="accordion-label">{label}</h6>
              {canOpen && (
                <Disclosure.Button
                  role="accordion-button"
                  className="accordion-button"
                >
                  <div
                    className={`arrow ${open ? "" : "closed"}`}
                    data-testid="close-button"
                  >
                    <ArrowUp />
                  </div>
                </Disclosure.Button>
              )}
            </div>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="accordion-body">
                <div className="accordion-body">{children}</div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};
