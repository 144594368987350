import { expectedAuthTypeQueryParam } from "@/components/Portal/UserAccount/Security/TwoFactorAuthStatus";
import { AuthenticatorAppSetup } from "@/components/Welcome/AuthenticatorSetup/AuthenticatorAppSetup";
import { portalRoutes } from "@/routes/portalRoutes";
import { ChangeTwoFactorAuthenticationMethod } from "@/services/model";
export const TwoFactorInitialAppSetupView = () => {
  const queryParams = new URLSearchParams();
  queryParams.set(
    expectedAuthTypeQueryParam,
    ChangeTwoFactorAuthenticationMethod.APP,
  );
  return (
    <div className="max-w-[560px]">
      <AuthenticatorAppSetup
        logoutOnAuthError={false}
        navigationTarget={
          portalRoutes.user.security.confirmBackupCodeReception +
          `?${queryParams.toString()}`
        }
      />
    </div>
  );
};
