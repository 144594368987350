import { Namespace, TFunction } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { TextContainer } from "@/components/Portal/UserAccount/PersonalDetails/TextContainer/TextContainer";
import { useQueryParam } from "@/hooks/useQueryParam";
import { portalRoutes } from "@/routes/portalRoutes";
import { useGetCustomer } from "@/services/api";
import {
  BasicCustomerDataTwoFactorMethod,
  ValidateTwoFactorAuthenticationMethod,
} from "@/services/model";
import { FCC } from "@/types";

import { TwoFactorCodeModal } from "./TwoFactorCodeModal";

export const expectedAuthTypeQueryParam = "expectedAuthType";

export const TwoFactorAuthStatus: FCC = () => {
  const { t } = useTranslation();
  const [expectedAuthType, setExpectedAuthType] = useQueryParam(
    expectedAuthTypeQueryParam,
  );
  const { data: customerData } = useGetCustomer(undefined, {
    query: {
      keepPreviousData: true,
      refetchInterval: (data) => {
        if (expectedAuthType) {
          if (expectedAuthType !== data?.twoFactorMethod) {
            return 5000;
          } else {
            setExpectedAuthType(undefined);
          }
        }
        return 0;
      },
      cacheTime: 0,
    },
  });
  const [showModal, setShowModal] = useState(false);
  const hasAnyTwoFactorAuth =
    customerData?.twoFactorMethod !== BasicCustomerDataTwoFactorMethod.STANDARD;

  const twoFactorText = getTwoFactorAuthText(
    t,
    (expectedAuthType as BasicCustomerDataTwoFactorMethod) ||
      customerData?.twoFactorMethod,
  );
  return (
    <div className="flex flex-col gap-6 md:min-w-[464px]">
      <div>
        <h3 className="text-secondary-100 font-semibold">
          {t("portal:user-account.security.2fa.title")}
        </h3>
        <p className="text-base">
          <span>{t("portal:user-account.security.2fa.description")}</span>
          <a
            className="text-primary-100 underline ml-2"
            href={t("portal:user-account.security.2fa.readMoreUrl")}
            target="_blank"
            rel="noreferrer"
          >
            {t("portal:user-account.security.2fa.readMore")}
          </a>
        </p>
      </div>
      <div>
        <SectionButtonHeader
          header={t("portal:user-account.security.2fa.header")}
        >
          {showModal && (
            <TwoFactorCodeModal
              setShowModal={setShowModal}
              method={
                customerData?.twoFactorMethod ===
                  BasicCustomerDataTwoFactorMethod.APP ||
                (expectedAuthType &&
                  expectedAuthType === BasicCustomerDataTwoFactorMethod.APP)
                  ? ValidateTwoFactorAuthenticationMethod.APP
                  : ValidateTwoFactorAuthenticationMethod.SMS
              }
            />
          )}

          {hasAnyTwoFactorAuth ? (
            <button
              type="button"
              className="text-primary-100 underline"
              onClick={() => setShowModal(true)}
            >
              {t("common.buttons.change")}
            </button>
          ) : (
            <Link to={portalRoutes.user.security.initialSetup}>
              {t("common.buttons.activate")}
            </Link>
          )}
        </SectionButtonHeader>
        <TextContainer>{twoFactorText}</TextContainer>
      </div>
    </div>
  );
};

const getTwoFactorAuthText = (
  t: TFunction<Namespace, undefined, Namespace>,
  authType?: BasicCustomerDataTwoFactorMethod,
) => {
  if (authType === BasicCustomerDataTwoFactorMethod.APP) {
    return t("portal:user-account.security.2fa.method.app");
  } else if (authType === BasicCustomerDataTwoFactorMethod.SMS) {
    return t("portal:user-account.security.2fa.method.sms");
  }
};
