import { atom, useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";

import { useDisplayTwoFactorAuthenticationData } from "@/services/auth";
import { SetUpTwoFactorAuthenticationReponse } from "@/services/model";

// Atom for saving the phone number used for two-factor authentication
// The backend needs some time to save the phone number so we save it here in the meantime
type phoneNumber = {
  phoneNumber: string;
  confirmed: boolean;
};
export const twoFactorPhoneNumberAtom = atom<phoneNumber>({
  phoneNumber: "",
  confirmed: false,
});

/**
 * Requests the phone number currently used for two-factor authentication.
 */
export const useRequestTwoFactorPhoneNumber = (): string | undefined => {
  const { mutateAsync } = useDisplayTwoFactorAuthenticationData();

  const [phoneNumberFromAtom] = useAtom(twoFactorPhoneNumberAtom);

  const [twoFactorData, setTwoFactorData] =
    useState<SetUpTwoFactorAuthenticationReponse | null>(null);
  const hasRequestedData = useRef(false);
  useEffect(() => {
    if (!hasRequestedData.current) {
      mutateAsync().then((data) => {
        setTwoFactorData(data);
      });
      hasRequestedData.current = true;
    }
  }, [mutateAsync]);

  if (twoFactorPhoneNumberAtom && phoneNumberFromAtom.confirmed) {
    return phoneNumberFromAtom?.phoneNumber;
  }

  if (twoFactorData?.phoneNumber) {
    return twoFactorData.phoneNumber;
  }
  return undefined;
};
