import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { RESET } from "jotai/utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGetCustomer } from "@/services/api";
import {
  accessTokenAtom,
  destinationAtom,
  refreshTokenAtom,
  verifierAtom,
} from "@/utils/atoms";
import { fetchAuthToken } from "@/utils/authentication";
import { log } from "@/utils/log";

import { useOnboardingRedirect } from "./useOnboardingRedirects";
import { useQueryParam } from "./useQueryParam";

/**
 * This hook redirects the user after login in to the onboarding or the
 * cockpit, depending on if they have finished the onboarding or not.
 * If the user hasn't finished the onboarding, they will be redirected to first
 * step that they haven't completed.
 * It returns a boolean indicating if the hook is in process.
 */
export const useLoginRedirect = (): boolean => {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom);
  const [destination, setDestination] = useAtom(destinationAtom);
  const verifier = useAtomValue(verifierAtom);
  const setRefreshToken = useSetAtom(refreshTokenAtom);
  const navigate = useNavigate();
  const [redirectToOnboarding, isRedirectingToOnboarding] =
    useOnboardingRedirect();
  // See if we find the code as a query parameter from the current window object
  // This will be present after the SSO redirects us to the Kundenportal again
  const [code] = useQueryParam("code");

  const { data: customerData } = useGetCustomer(undefined, {
    query: {
      enabled: !!accessToken,
    },
  });

  const authorized = !!accessToken;
  useEffect(() => {
    if (code && verifier) {
      setIsRedirecting(true);
      fetchAuthToken(code, verifier)
        .then(({ access_token, refresh_token }) => {
          setAccessToken(access_token);
          setRefreshToken(refresh_token);
        })
        .catch((e) => {
          log(e);
          setIsRedirecting(false);
        });
    }
  }, [setAccessToken, setRefreshToken, verifier, code]);

  useEffect(() => {
    if (authorized && !!customerData) {
      setDestination(RESET);

      if (customerData.onboardingFinished) {
        setIsRedirecting(false);
        navigate(destination ?? "/portal/cockpit", { replace: true });
      } else {
        redirectToOnboarding(customerData);
        setIsRedirecting(isRedirectingToOnboarding);
      }
    }
  }, [
    authorized,
    destination,
    navigate,
    setDestination,
    customerData,
    redirectToOnboarding,
    isRedirectingToOnboarding,
  ]);

  return isRedirecting;
};
