import { expectedAuthTypeQueryParam } from "@/components/Portal/UserAccount/Security/TwoFactorAuthStatus";
import { AuthenticatorBackupCodeConfirm } from "@/components/Welcome/AuthenticatorSetup/AuthenticatorAppConfirm";
import { useQueryParam } from "@/hooks/useQueryParam";
import { portalRoutes } from "@/routes/portalRoutes";

export const TwoFactorBackupConfirmView = () => {
  const [expectedAuthType] = useQueryParam(expectedAuthTypeQueryParam);
  const target = expectedAuthType
    ? portalRoutes.user.security.base +
      `?${expectedAuthTypeQueryParam}=${expectedAuthType.toString()}`
    : portalRoutes.user.security.base;
  return (
    <div className="max-w-[560px]">
      <AuthenticatorBackupCodeConfirm successTarget={target} />
    </div>
  );
};
