import "./PersonalDetails.scss";

import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { useSetAtom } from "jotai";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FCC } from "types";

import { Button, SubmitButton } from "@/components/Interface/Button/Button";
import { Checkbox } from "@/components/Interface/FormFields/Checkbox/Checkbox";
import { ErrorMessage } from "@/components/Interface/FormFields/ErrorMessage/ErrorMessage";
import { HighlightPanel } from "@/components/Interface/HighlightPanel/HighlightPanel";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { Step } from "@/components/Onboarding/Steps/Step";
import { useIsAnonymousUser } from "@/hooks/useIsAnonymousUser";
import { DataContext } from "@/provider/DataContextProvider";
import {
  createCustomer,
  getCustomer,
  useGetCustomer,
  useUpdateCustomer,
} from "@/services/api";
import { BasicCustomerData } from "@/services/model";
import { API_ROOT, USER_AUTHORIZATION } from "@/utils/apiUrls";
import {
  accessTokenAtom,
  esimJourneyBasketIdAtom,
  refreshTokenAtom,
} from "@/utils/atoms";
import {
  hasValidPersonalDetails,
  transformBasicCustomerDataToPersonalDetailsForm,
  transformPersonalDetailsFormToBasicCustomerData,
} from "@/utils/customerUtils";
import { getEntry } from "@/utils/dataContextHelpers";
import { log } from "@/utils/log";

import { AuthFormFields, AuthFormFieldsType } from "./AuthFormFields";
import {
  BusinessPersonalDetailsFormFields,
  excludedFieldsBusiness,
  excludedFieldsBusinessAnonymous,
  excludedFieldsPrivate,
  excludedFieldsPrivateAnonymous,
  PersonalDetailsFormFieldKeys,
  PersonalDetailsFormFields,
} from "./PersonalDetailsFormFields/PersonalDetailsFormFields";

type AuthAndPersonalDetailsFormFields = BusinessPersonalDetailsFormFields &
  AuthFormFieldsType &
  TermsAndConditionsFieldType;

export const PersonalDetails: FCC = () => {
  const [isExistingAccount, setIsExistingAccount] = useState<
    boolean | undefined
  >(undefined);
  const [customerHasPersonalDetails, setCustomerHasPersonalDetails] = useState<
    boolean | undefined
  >(undefined);
  const [selectedCustomerTypeTabIndex, setSelectedCustomerTypeTabIndex] =
    useState(0);

  const { dataContext, setDataContext } = useContext(DataContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isAnonymousUser] = useIsAnonymousUser();

  const isBusinessCustomer = !!dataContext.personalDetails?.organizationName;
  useEffect(() => {
    if (isBusinessCustomer) {
      setSelectedCustomerTypeTabIndex(1);
    }
  }, [isBusinessCustomer]);

  const { data: customerData, isFetching: isLoadingCustomer } = useGetCustomer(
    undefined,
    {
      query: {
        enabled: !isAnonymousUser,
        onSuccess: (data) => {
          if (hasValidPersonalDetails(data)) {
            setCustomerHasPersonalDetails(true);

            setDataContext((prev) => ({
              ...prev,
              personalDetails:
                transformBasicCustomerDataToPersonalDetailsForm(data),
            }));
          }
        },
      },
    },
  );

  const { mutateAsync: updateCustomer, isLoading: isUpdatingCustomer } =
    useUpdateCustomer();

  const formMethods = useForm<AuthAndPersonalDetailsFormFields>({
    mode: "onChange",
  });

  useEffect(() => {
    if (!!customerData && customerHasPersonalDetails) {
      const formPrefillData =
        transformBasicCustomerDataToPersonalDetailsForm(customerData);

      Object.keys(formPrefillData).forEach((key) => {
        formMethods.setValue(
          key as keyof Omit<BusinessPersonalDetailsFormFields, "terms">,
          formPrefillData[
            key as keyof Omit<BusinessPersonalDetailsFormFields, "terms">
          ],
          { shouldDirty: false, shouldTouch: false },
        );
      });

      formMethods.trigger(Object.values(PersonalDetailsFormFieldKeys));
    }
  }, [customerHasPersonalDetails, customerData, formMethods]);

  const formValues = formMethods.watch();
  useLayoutEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);

  const isValid = customerData && formValues && formMethods.formState.isValid;

  const setAccessToken = useSetAtom(accessTokenAtom);
  const setRefreshToken = useSetAtom(refreshTokenAtom);
  const setEsimJourneyBasketId = useSetAtom(esimJourneyBasketIdAtom);
  const persistCustomerAndGoToCheckout = (
    customerData: BasicCustomerData,
    target = "/onboarding/5",
  ) => {
    setDataContext((prev) => ({
      ...prev,
      allowedMaxOnboardingStep: 5,
      customerData: customerData,
    }));
    navigate(target);
  };
  type CustomerTypeAndSegment = {
    customerType: { id: number };
    customerSegment: { id: number };
  };
  const navigateToNext = async (
    formValues: AuthAndPersonalDetailsFormFields,
  ) => {
    const customerTypeAndCustomerSegment: CustomerTypeAndSegment = {
      // ...If we have a business customer (organizationName is set),
      ...(formValues.organizationName !== undefined
        ? // ... we want to create the customer as customerType: 2, meaning they are a business customer.
          { customerType: { id: 2 }, customerSegment: { id: 1 } }
        : // ... else they are a private customer with customerType: 1
          { customerType: { id: 1 }, customerSegment: { id: 2 } }),
    };

    if (isAnonymousUser) {
      const loginAndPersistBasket = () => {
        const fetchPostBody = new URLSearchParams();
        fetchPostBody.append("grant_type", "password");
        fetchPostBody.append("username", `${formValues?.email}`);
        fetchPostBody.append("password", `${formValues?.password}`);
        return fetch(`${API_ROOT}/api-auth-server/oauth/token`, {
          credentials: "include",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Accept-Language": "en,de;q=0.5",
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: USER_AUTHORIZATION,
            "Sec-Fetch-Dest": "empty",
            "Sec-Fetch-Mode": "cors",
            "Sec-Fetch-Site": "same-origin",
            "Sec-GPC": "1",
          },
          body: fetchPostBody,
          method: "POST",
          mode: "cors",
        }).then((res) =>
          res.json().then((res) => {
            if (!res.access_token || !res.refresh_token) {
              throw new Error("No tokens received after onboarding login");
            }
            setAccessToken(res.access_token);
            setRefreshToken(res.refresh_token);
            if (!dataContext.basket?.id) {
              throw new Error("No basket id received after onboarding login");
            }
            setEsimJourneyBasketId(dataContext.basket.id);
          }),
        );
      };

      // Create account
      if (
        !isExistingAccount &&
        formValues &&
        Object.keys(formMethods.formState.errors).length === 0
      ) {
        const postData = {
          ...transformPersonalDetailsFormToBasicCustomerData(formValues, {}),
          ...customerTypeAndCustomerSegment,
        };
        // Removing the phone data cause the backend can't add it on the initial create user
        delete postData.phone;
        createCustomer({
          header: { source: 1, client: 1 },
          basicCustomerData: postData,
          authentification: {
            login: formValues?.email,
            password: formValues?.password,
          },
        })
          // Make the call to sign in
          .then(() => {
            loginAndPersistBasket().then(() => {
              // Here it is necessary to update the customer to add the phone number (cannot be done in create call...)
              // It is necessary to be signed in before the update of the customer.
              getCustomer().then((freshCustomer) => {
                // Update the customer to persist the phone number too.
                handleCustomerUpdate(
                  freshCustomer,
                  customerTypeAndCustomerSegment,
                );
              });
            });
          })
          .catch((err) => log("Error when creating an anonymous user", err));
      }

      // Login
      else {
        loginAndPersistBasket()
          .then(() => {
            getCustomer().then((customerData) => {
              if (hasValidPersonalDetails(customerData)) {
                // Upon login, a customer who has completed the onboarding process should be redirected
                // to the 'add device' flow within the portal. A customer without a device (unfinished onboarding)
                // should be sent to the checkout of the onboarding process.
                const redirectTarget = customerData.onboardingFinished
                  ? "/portal/add-device/4/checkout"
                  : "/onboarding/5";
                persistCustomerAndGoToCheckout(customerData, redirectTarget);
              }
            });
          })
          .catch((err) => {
            // Set formstate to error
            formMethods.setError("password", {
              type: "backendError",
              message: t(
                "portal:user-account.personalDetails.loginData.passwordError",
              ),
            });
          });
      }
    }

    if (isValid) {
      handleCustomerUpdate(customerData, customerTypeAndCustomerSegment);
    }
  };

  function handleCustomerUpdate(
    customerData: BasicCustomerData,
    customerTypeAndCustomerSegment: CustomerTypeAndSegment,
  ) {
    const customerDataToSubmit = {
      ...transformPersonalDetailsFormToBasicCustomerData(
        formValues,
        customerData,
      ),
      ...customerTypeAndCustomerSegment,
    };

    updateCustomer({
      data: customerDataToSubmit,
    })
      .then((data) => {
        setDataContext((prev) => ({
          ...prev,
          personalDetails:
            transformBasicCustomerDataToPersonalDetailsForm(data),
        }));
        persistCustomerAndGoToCheckout(customerDataToSubmit);
      })
      .catch((error) => {
        if (`${error?.response?.data}`.includes("invalid phone number")) {
          formMethods.setError("phone", {
            type: "backendError",
          });
        } else {
          log("Error in customer form: " + error);
        }
      });
  }

  const isLoading = isLoadingCustomer || isUpdatingCustomer;

  const disablePersonalDetailsForm =
    isAnonymousUser && isExistingAccount === undefined;

  return (
    <Step
      id="onboarding-personal-details"
      headline={t("Onboarding.sections.personal-details.header")}
      subheadline={t(
        `Onboarding.sections.personal-details.subtitle${
          getEntry(dataContext)?.esim ? "Esim" : "Sim"
        }`,
      )}
    >
      {isLoading && <LoadingSpinner />}

      <form onSubmit={formMethods.handleSubmit(navigateToNext)}>
        <FormProvider {...formMethods}>
          {isAnonymousUser && (
            <div className="mt-8">
              <h3 className="text-secondary-100">
                {t("portal:user-account.personalDetails.loginData.title")}
              </h3>

              <p className="my-6 text-black">
                {t(
                  `Onboarding.sections.personal-details.loginSubtitle.${
                    isExistingAccount ? "existingAccount" : "newAccount"
                  }`,
                )}
              </p>

              <AuthFormFields
                isExistingAccount={isExistingAccount}
                setIsExistingAccount={setIsExistingAccount}
              />
            </div>
          )}

          {/* We show this form in all cases, except on 'login' mode. */}
          {isAnonymousUser && isExistingAccount ? null : (
            <div className="mt-10">
              {isAnonymousUser && (
                <h3
                  className={clsx(
                    "text-secondary-100 mb-6",
                    disablePersonalDetailsForm && "opacity-20",
                  )}
                >
                  {t("translation:Onboarding.sections.personal-details.header")}
                </h3>
              )}
              <Tab.Group
                selectedIndex={selectedCustomerTypeTabIndex}
                onChange={setSelectedCustomerTypeTabIndex}
              >
                <Tab.List
                  className={clsx(disablePersonalDetailsForm && "opacity-20")}
                >
                  <Tab disabled={disablePersonalDetailsForm}>
                    {t("Onboarding.sections.personal-details.tabs.private")}
                  </Tab>

                  <Tab disabled={disablePersonalDetailsForm}>
                    {t("Onboarding.sections.personal-details.tabs.business")}
                  </Tab>
                </Tab.List>

                <Tab.Panels>
                  <Tab.Panel
                    id="private"
                    className="mt-6"
                    tabIndex={disablePersonalDetailsForm ? -1 : 0}
                  >
                    <PersonalDetailsFormFields
                      disableAllFields={disablePersonalDetailsForm}
                      excludedFields={
                        isAnonymousUser
                          ? excludedFieldsPrivateAnonymous
                          : excludedFieldsPrivate
                      }
                    />
                  </Tab.Panel>

                  <Tab.Panel
                    className="mt-6"
                    tabIndex={disablePersonalDetailsForm ? -1 : 0}
                  >
                    <PersonalDetailsFormFields
                      disableAllFields={disablePersonalDetailsForm}
                      excludedFields={
                        isAnonymousUser
                          ? excludedFieldsBusinessAnonymous
                          : excludedFieldsBusiness
                      }
                    />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>

              <div className="h-16" />

              <HighlightPanel>
                <p>{t("Onboarding.sections.personal-details.hint.text")}</p>
              </HighlightPanel>

              {isAnonymousUser && !isExistingAccount && (
                <TermsAgreementField disabled={disablePersonalDetailsForm} />
              )}
            </div>
          )}
          <div className="nav-button-container">
            <Button
              type="button"
              onClick={() => navigate("../../3/rate-selection")}
              className="accent inverted"
            >
              {t("Common.label.back")}
            </Button>

            <SubmitButton
              dataTestid="continue-button"
              className="accent"
              label={t("Common.label.forward")}
            />
          </div>
        </FormProvider>
      </form>
    </Step>
  );
};

type TermsAndConditionsFieldType = {
  termsAndConditions?: boolean;
};

const TermsAgreementField = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext<AuthAndPersonalDetailsFormFields>();

  return (
    <div className="mt-8 font-small text-black">
      <Checkbox
        disabled={disabled}
        id="termsAgreement"
        name="termsAndConditions"
        value="yes"
        labelComponent={
          <p className={clsx(disabled && "opacity-20")}>
            <Trans
              components={[
                <a
                  target="_blank"
                  key="termsAndConditionsLink"
                  href={t("Footer.termsConditions.url")}
                  className="text-primary-100 underline"
                  rel="noreferrer"
                />,
                <a
                  target="_blank"
                  key="dataProtectionLink"
                  href={t("Footer.dataProtection.url")}
                  className="text-primary-100 underline"
                  rel="noreferrer"
                />,
              ]}
              i18nKey="Onboarding.sections.personal-details.acceptTerms"
            />
          </p>
        }
        errors={errors}
        register={register}
      />
      {errors && errors["termsAndConditions"] && (
        <ErrorMessage
          name="termsAndConditions"
          message={t("label.validation.required")}
        />
      )}
    </div>
  );
};
