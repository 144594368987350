/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 112.0.0-SNAPSHOT
 */

export type GetAvailableSunriseRoamingPackagesPackageType =
  (typeof GetAvailableSunriseRoamingPackagesPackageType)[keyof typeof GetAvailableSunriseRoamingPackagesPackageType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAvailableSunriseRoamingPackagesPackageType = {
  Data_CH: "Data_CH",
  Data_Roaming: "Data_Roaming",
  Watch_Sim: "Watch_Sim",
} as const;
