import "@/components/Interface/FormFields/FormField/FormField.scss";
import "./DateInput.scss";

import React from "react";

import { CalendarBlank } from "@/assets/icons/icons";
import {
  FormField,
  FormFieldProps,
} from "@/components/Interface/FormFields/FormField/FormField";

/**
 * This component uses the native HTML input type="date" under the hood. Because
 * of this, it must take a value in YYYY-MM-DD format, which will be displayed
 * it in the browser's locale (not the app's locale).
 */
export const DateInput = <TFormValues extends object>(
  props: React.PropsWithChildren<FormFieldProps<TFormValues>>,
) => {
  return (
    <div className="form-field-container">
      <FormField
        {...props}
        type="date"
        icon={
          <CalendarBlank
            data-testid="calendar-icon"
            className="cursor-pointer"
            onClick={async (event) => {
              const elem = event.target as HTMLElement;
              const input = elem.parentNode?.parentNode
                ?.childNodes[0] as HTMLInputElement;
              // We first focus the field, as this will always be possible.
              if (input && input.click) {
                input.click();
              }
              if (Object.keys(input).includes("showPicker")) {
                // Then we try to show the date picker, but only few browsers support this.
                input?.showPicker();
              }
            }}
          />
        }
      >
        {props.children}
      </FormField>
    </div>
  );
};
