import { useAtom } from "jotai";
import { FC } from "react";

import { Background } from "@/components/Layout/Background/Background";
import { Footer } from "@/components/Layout/Footer/Footer";
import { WelcomeContent } from "@/components/Welcome/WelcomeContent/WelcomeContent";
import { WelcomeHeader } from "@/components/Welcome/WelcomeHeader/WelcomeHeader";
import { useLoginRedirect } from "@/hooks/useLoginRedirect";
import { destinationAtom } from "@/utils/atoms";

export const Welcome: FC = () => {
  const [, setDestination] = useAtom(destinationAtom);
  setDestination("/authenticator");
  const isRedirecting = useLoginRedirect();

  return isRedirecting ? null : (
    <Background>
      <WelcomeHeader />
      <WelcomeContent />
      <Footer className="welcome-screen" />
    </Background>
  );
};
