import { useMutation } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";

import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { useAlert } from "@/hooks/useAlert";
import { useQueryParam } from "@/hooks/useQueryParam";
import {
  AGENT_LOGIN_URL,
  BASIC_PORTAL_AUTHORIZATION,
  TOKEN_URL,
} from "@/utils/apiUrls";
import { accessTokenAtom, AlertTypes, refreshTokenAtom } from "@/utils/atoms";
import { IS_AGENT_LOGIN } from "@/utils/authentication";

// TODO: Is not a route component, but a component that is used in a route component. Should be moved.
export const AgentLogin: React.FC = () => {
  const setAccessToken = useSetAtom(accessTokenAtom);
  const setRefreshToken = useSetAtom(refreshTokenAtom);
  const showAlert = useAlert();
  const [customer] = useQueryParam("customer");
  const [hash] = useQueryParam("hash");
  const [user] = useQueryParam("user");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mutate: loginAsAgent, isError: agentDataIsError } = useMutation(
    ["agentLogin"],
    (data: { customer: string; hash: string; user: string }) =>
      // Get a OAuth token by using the public user portal:portal
      fetch(`${TOKEN_URL.toString()}?grant_type=client_credentials&scope=all`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BASIC_PORTAL_AUTHORIZATION,
        },
      })
        .then(async (res: any) => await res.json())
        .then((json: any) =>
          // If we receive an access_token, call the agentLogin endpoint with the query params given into this mutation
          json.access_token
            ? fetch(AGENT_LOGIN_URL.toString(), {
                method: "POST",
                body: JSON.stringify({
                  customer: data.customer,
                  hash: data.hash,
                  user: data.user,
                }),
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${json.access_token}`,
                },
              })
            : Promise.reject(),
        )
        .then((res) => res.json()),
    {
      onError: () => {
        showAlert({
          text: t("Error.agentLogin.failed"),
          type: AlertTypes.error,
        });
      },
      // Save the received tokens and go with them into the portal
      onSuccess: (agentLoginData) => {
        if (agentLoginData?.access_token !== undefined) {
          // Clear any previous local storage data
          localStorage.clear();
          // Set the access token
          setAccessToken(agentLoginData.access_token);
          // Access token is a placeholder, it is used to determine if there is an agentlogin
          setRefreshToken(IS_AGENT_LOGIN);
        }
        navigate("/portal/cockpit", { replace: true });
      },
    },
  );
  useEffect(() => {
    if (
      typeof customer === "string" &&
      typeof hash === "string" &&
      typeof user === "string"
    ) {
      loginAsAgent({ customer, hash, user });
    }
  }, [loginAsAgent, customer, hash, user]);
  if (agentDataIsError) {
    return <Navigate to="/404" replace />;
  }
  return <LoadingSpinner />;
};
