import "./PersonalDetailsFormFields.scss";

import clsx from "clsx";
import { FC, useEffect, useMemo } from "react";
import { Controller, Path, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import ArrowLineUpRight from "@/assets/icons/ArrowLineUpRight.svg?react";
import { Combobox } from "@/components/Interface/FormFields/Combobox/Combobox";
import { DateInput } from "@/components/Interface/FormFields/DateInput/DateInput";
import {
  FormField,
  FormFieldWithHint,
} from "@/components/Interface/FormFields/FormField/FormField";
import { RadioButton } from "@/components/Interface/FormFields/RadioButton/RadioButton";
import {
  doesRequireUIDCheck,
  LEGAL_FORMS,
} from "@/components/Onboarding/Onboarding.constants";
import type { Country } from "@/constants/countries";
import { SCHWEIZ } from "@/constants/countries";
import { useGetSortedCountries } from "@/hooks/useGetSortedCountries";
import { LookupValue } from "@/services/model";
import {
  allowedBusinessCustomerNameRegex,
  getLocalizedBirthday,
} from "@/utils/customerUtils";
import { uidIsValid } from "@/utils/uidChecksum/uidChecksum";

export enum FormOfAddresses {
  "other",
  "mr",
  "mrs",
}
export enum PersonalDetailsFormFieldKeys {
  formOfAddress = "formOfAddress",
  firstName = "firstName",
  lastName = "lastName",
  street = "street",
  houseNumber = "houseNumber",
  postalDeliveryNotes = "postalDeliveryNotes",
  zip = "zip",
  city = "city",
  country = "country",
  phone = "phone",
  dateOfBirth = "dateOfBirth",
}
export enum BusinessPersonalDetailsFormFieldKeys {
  organizationName = "organizationName",
  organizationForm = "organizationForm",
  organizationId = "organizationId",
}
// Form keys combined in a const with a short name
export const fk = {
  ...PersonalDetailsFormFieldKeys,
  ...BusinessPersonalDetailsFormFieldKeys,
};
// For anonymous private forms, exclude the business fields
export const excludedFieldsPrivateAnonymous = [
  fk.organizationName,
  fk.organizationForm,
  fk.organizationId,
];
// For private forms, exclude the business fields
export const excludedFieldsPrivate = [...excludedFieldsPrivateAnonymous];
//For the anonymous business form, exclude the birthday field
export const excludedFieldsBusinessAnonymous = [
  PersonalDetailsFormFieldKeys.dateOfBirth,
];
//For the business form, exclude the birthday field
export const excludedFieldsBusiness = [...excludedFieldsBusinessAnonymous];

export type PersonalDetailsFormFieldsType = {
  [fk.formOfAddress]: FormOfAddresses;
  [fk.firstName]: string;
  [fk.lastName]: string;
  [fk.street]: string;
  [fk.houseNumber]: string;
  [fk.postalDeliveryNotes]?: string;
  [fk.zip]: string;
  [fk.city]: string;
  [fk.country]: Country;
  [fk.phone]: string;
  [fk.dateOfBirth]: string;
};

export type BusinessPersonalDetailsFormFields =
  PersonalDetailsFormFieldsType & {
    [fk.organizationName]: string;
    [fk.organizationForm]: LookupValue;
    [fk.organizationId]: string;
  };

type PersonalDetailsFormFieldsProps = {
  disableAllFields?: boolean;
  excludedFields?: Path<BusinessPersonalDetailsFormFields>[];
  readonlyFields?: Path<BusinessPersonalDetailsFormFields>[];
};

// TODO Refactor the business details logic into a separate component
//  to keep this one's responsibilities small
export const PersonalDetailsFormFields: FC<PersonalDetailsFormFieldsProps> = ({
  disableAllFields = false,
  excludedFields = [],
  readonlyFields = [],
}: PersonalDetailsFormFieldsProps) => {
  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: "Onboarding.sections.personal-details.form",
  });
  const {
    watch,
    register,
    trigger,
    unregister,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<BusinessPersonalDetailsFormFields>();
  // This might be undefined if we unregistered the business fields
  const organizationForm = watch(fk.organizationForm) as
    | LookupValue
    | undefined;
  const sortedCountries = useGetSortedCountries();
  const organizationIdIsRequired = useMemo(
    () =>
      organizationForm !== undefined &&
      organizationForm.id !== 0 &&
      doesRequireUIDCheck(organizationForm),
    [organizationForm],
  );
  const includesOrganizationId = excludedFields?.includes(fk.organizationId);

  useEffect(() => {
    if (includesOrganizationId && organizationForm?.id !== 0) {
      trigger(fk.organizationId);
    }
  }, [trigger, organizationForm?.id, includesOrganizationId]);

  // Unregister all excluded fields
  useEffect(() => {
    excludedFields?.forEach((fieldName) => unregister(fieldName));
  }, [excludedFields, unregister]);

  return (
    <div id="personal-details-form-fields">
      <p
        className={clsx("text-xs text-black", disableAllFields && "opacity-20")}
      >
        <>* {t("requiredInfo")}</>
      </p>
      {!excludedFields.includes(fk.organizationName) && (
        <FormField<BusinessPersonalDetailsFormFields>
          id="organization-name-input"
          label={t("label.organizationName")}
          autocomplete="organization"
          name={fk.organizationName}
          errors={errors}
          register={register}
          type="text"
          placeholder={t("placeholder.organizationName")}
          options={{
            disabled: disableAllFields,
            required:
              excludedFields && t("translation:label.validation.required"),
            pattern: {
              message: t(
                "translation:label.validation.businessCustomerNameInvalidChars",
              ),
              value: allowedBusinessCustomerNameRegex,
            },
          }}
          showRequiredIndicator
        />
      )}
      {!excludedFields.includes(fk.organizationForm) && (
        <div>
          <Controller
            control={control}
            name={fk.organizationForm}
            rules={{ required: true, validate: (value) => value.id !== 0 }}
            render={({ field }) => (
              <Combobox<LookupValue>
                field={field}
                label={t("label.organizationForm")}
                hasError={errors.organizationForm !== undefined}
                displayValue={(legalForm: LookupValue) => {
                  return t(
                    `legalForms.${legalForm.id}`,
                    // In case no translation is available,
                    // the original description is given as default.
                    `${legalForm.description}`,
                  );
                }}
                dataTestId="legalFormCombobox"
                options={LEGAL_FORMS}
                placeholder={t("placeholder.organizationForm")}
                showRequiredIndicator
              />
            )}
          />
          {(errors.organizationForm?.type === "required" ||
            errors.organizationForm?.type === "validate") && (
            <p className="text-sm text-secondary-100">
              {t("translation:label.validation.required")}
            </p>
          )}
        </div>
      )}
      {!excludedFields.includes(fk.organizationId) && (
        <FormField<BusinessPersonalDetailsFormFields>
          id="organization-id-input"
          label={t("label.organizationId")}
          autocomplete="organization"
          name={fk.organizationId}
          errors={errors}
          register={register}
          type="text"
          placeholder={t("placeholder.organizationId")}
          options={{
            disabled: disableAllFields,
            required:
              excludedFields &&
              organizationIdIsRequired &&
              t("translation:label.validation.required"),
            validate: (value: any) =>
              uidIsValid(value) || value === ""
                ? true
                : t("validation.organizationId"),

            onChange: (e: KeyboardEvent) => {
              const input = e.target as HTMLInputElement;
              const value = input.value
                .toUpperCase()
                .replace(/[^a-zA-Z\d]/g, "");
              setValue(fk.organizationId, value);
            },
          }}
          showRequiredIndicator
          icon={
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.uid.admin.ch/Search.aspx"
            >
              <ArrowLineUpRight />
            </a>
          }
        />
      )}
      <fieldset data-testid="form-of-address-group">
        <legend
          className={clsx(
            "text-lg text-primary-100 font-semibold mb-2",
            readonlyFields.includes(fk.formOfAddress) && "opacity-20",
            disableAllFields && "opacity-20",
          )}
        >
          {t(`label.formOfAddress`)}
          {!readonlyFields.includes(fk.formOfAddress) && " *"}
        </legend>

        <div
          className={clsx(
            "form-of-address",
            errors.formOfAddress?.type === "required" && "with-errors",
          )}
        >
          <RadioButton
            label={t("formOfAddress.mrs")}
            name={fk.formOfAddress}
            value={FormOfAddresses.mrs}
            errors={errors}
            register={register}
            options={{
              disabled: disableAllFields,
              readOnly: readonlyFields.includes(fk.formOfAddress),
              required: !readonlyFields.includes(fk.formOfAddress),
            }}
          />
          <RadioButton
            label={t("formOfAddress.mr")}
            name={fk.formOfAddress}
            value={FormOfAddresses.mr}
            errors={errors}
            register={register}
            options={{
              disabled: disableAllFields,
              readOnly: readonlyFields.includes(fk.formOfAddress),
              required: !readonlyFields.includes(fk.formOfAddress),
            }}
          />
          <RadioButton
            label={t("formOfAddress.other")}
            name={fk.formOfAddress}
            value={`${FormOfAddresses.other}`}
            errors={errors}
            register={register}
            options={{
              disabled: disableAllFields,
              readOnly: readonlyFields.includes(fk.formOfAddress),
              required: !readonlyFields.includes(fk.formOfAddress),
            }}
          />
        </div>
        {errors.formOfAddress?.type === "required" && (
          <p className="text-sm text-secondary-100 mt-2">
            {t("translation:label.validation.required")}
          </p>
        )}
      </fieldset>
      <div className="names">
        <FormFieldWithHint<BusinessPersonalDetailsFormFields>
          id="first-name-input"
          label={
            readonlyFields.includes(fk.firstName)
              ? t("label.firstNameShort")
              : t("label.firstName")
          }
          autocomplete="given-name"
          name={fk.firstName}
          errors={errors}
          register={register}
          type="text"
          hint={t("hint.firstName")}
          placeholder={t("placeholder.firstName")}
          options={{
            disabled: disableAllFields,
            readOnly: readonlyFields.includes(fk.firstName),
            required: t("translation:label.validation.required"),
          }}
          showRequiredIndicator
        />
        <FormFieldWithHint<BusinessPersonalDetailsFormFields>
          id="last-name-input"
          label={
            readonlyFields.includes(fk.lastName)
              ? t("label.lastNameShort")
              : t("label.lastName")
          }
          autocomplete="family-name"
          name={fk.lastName}
          errors={errors}
          register={register}
          type="text"
          hint={t("hint.lastName")}
          placeholder={t("placeholder.lastName")}
          options={{
            disabled: disableAllFields,
            required: t("translation:label.validation.required"),
            readOnly: readonlyFields.includes(fk.lastName),
          }}
          showRequiredIndicator
        />
      </div>
      <div className="street">
        <FormField<BusinessPersonalDetailsFormFields>
          id="street-input"
          label={t("label.street")}
          name={fk.street}
          errors={errors}
          register={register}
          type="text"
          placeholder={t(
            `placeholder.${
              excludedFields?.includes(fk.organizationName)
                ? fk.street
                : "company-street"
            }`,
          )}
          options={{
            disabled: disableAllFields,
            required: t("translation:label.validation.required"),
            readOnly: readonlyFields.includes(fk.street),
            maxLength: 40,
          }}
          showRequiredIndicator
        />
        <FormField<BusinessPersonalDetailsFormFields>
          id="house-number-input"
          label={t("label.houseNumber")}
          name={fk.houseNumber}
          errors={errors}
          register={register}
          type="text"
          placeholder={t("placeholder.houseNumber")}
          options={{
            disabled: disableAllFields,
            required: t("translation:label.validation.required"),
            readOnly: readonlyFields.includes(fk.houseNumber),
            maxLength: 10,
          }}
          showRequiredIndicator
        />
      </div>
      <div className="addressSupplement">
        <FormField<BusinessPersonalDetailsFormFields>
          id="address-supplement-input"
          label={t("label.addressSupplement")}
          autocomplete="address-level4"
          name={fk.postalDeliveryNotes}
          errors={errors}
          register={register}
          type="text"
          placeholder={t("placeholder.addressSupplement")}
          options={{
            disabled: disableAllFields,
            required: false,
            readOnly: readonlyFields.includes(fk.postalDeliveryNotes),
          }}
          showRequiredIndicator
        />
      </div>
      <div className="zipAndCity">
        <FormField<BusinessPersonalDetailsFormFields>
          id="zip-code-input"
          label={t("label.zip")}
          autocomplete="postal-code"
          name={fk.zip}
          errors={errors}
          register={register}
          type="text"
          placeholder={t("placeholder.zip")}
          options={{
            disabled: disableAllFields,
            required: t("translation:label.validation.required"),
            readOnly: readonlyFields.includes(fk.zip),
            maxLength: 10,
          }}
          showRequiredIndicator
        />
        <FormField<BusinessPersonalDetailsFormFields>
          id="city-input"
          label={t("label.city")}
          autocomplete="address-level2"
          name={fk.city}
          errors={errors}
          register={register}
          type="text"
          placeholder={t("placeholder.city")}
          options={{
            disabled: disableAllFields,
            required: t("translation:label.validation.required"),
            readOnly: readonlyFields.includes(fk.city),
          }}
          showRequiredIndicator
        />
      </div>
      <div className="country">
        <Controller
          control={control}
          name={fk.country}
          defaultValue={SCHWEIZ}
          render={({ field }) => (
            <Combobox<Country>
              displayValue={(country?: Country) =>
                t(
                  country
                    ? `translation:countries.${country.ALPHA3}`
                    : "placeholder.country",
                )
              }
              field={field}
              disabled={disableAllFields}
              options={sortedCountries}
              dataTestId="countryCombobox"
              label={t("label.country")}
              placeholder={t("placeholder.country")}
              showRequiredIndicator
            />
          )}
        />
      </div>
      <div
        className={clsx(
          !excludedFields.includes(fk.dateOfBirth) && "phoneAndDateOfBirth",
        )}
      >
        <FormFieldWithHint<BusinessPersonalDetailsFormFields>
          id="phone-number-input"
          label={t("label.phone")}
          autocomplete="tel"
          name={fk.phone}
          hint={t("placeholder.phoneHint")}
          errors={errors}
          register={register}
          type="tel"
          placeholder={t("placeholder.phone")}
          options={{
            disabled: disableAllFields,
            pattern: {
              value: /^(\+|00)[1-9]{1}[0-9]{0,2}[\d\s]*$/,
              message: t("translation:label.validation.phone"),
            },
            required: t("translation:label.validation.required"),
            readOnly: readonlyFields.includes(
              PersonalDetailsFormFieldKeys.phone,
            ),
            backendError: t("translation:label.validation.phoneBackend"),
          }}
          showRequiredIndicator
        />
        {!excludedFields.includes(fk.dateOfBirth) &&
          (readonlyFields.includes(PersonalDetailsFormFieldKeys.dateOfBirth) ? (
            <FormFieldWithHint<BusinessPersonalDetailsFormFields>
              id="date-of-birth-input"
              label={t("label.dateOfBirth")}
              name={fk.dateOfBirth}
              errors={errors}
              register={register}
              type="text"
              placeholder={t("placeholder.dateOfBirth")}
              value={getLocalizedBirthday(watch(fk.dateOfBirth), i18n.language)}
              options={{ disabled: disableAllFields, readOnly: true }}
              showRequiredIndicator
            />
          ) : (
            <DateInput<BusinessPersonalDetailsFormFields>
              id="birth-date-input"
              label={t("label.dateOfBirth")}
              autocomplete="bday"
              name={fk.dateOfBirth}
              errors={errors}
              register={register}
              placeholder={t("placeholder.dateOfBirth")}
              value={watch(fk.dateOfBirth)}
              options={{
                disabled: disableAllFields,
                required: t("translation:label.validation.required"),
                // Birthdate is valid if
                // - entered date is an actual date (better safe than sorry)
                // - date is before now
                // - date is no longer ago then 120 years
                validate: (value: string) =>
                  isNaN(Date.parse(value)) ||
                  Date.now() < Date.parse(value) ||
                  new Date().setFullYear(new Date().getFullYear() - 120) >
                    Date.parse(value)
                    ? (t("translation:label.validation.invalidBirthdate") ??
                      "Invalid birthdate")
                    : true,
              }}
              showRequiredIndicator
            />
          ))}
      </div>
    </div>
  );
};
