import { useQueryClient } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";

import { useQueryParam } from "@/hooks/useQueryParam";
import { clearContext } from "@/provider/DataContextProvider";
import { useLogout as useApiLogout } from "@/services/auth";
import { accessTokenAtom, refreshTokenAtom, verifierAtom } from "@/utils/atoms";

export const useLogout = () => {
  const setAccessToken = useSetAtom(accessTokenAtom);
  const setRefreshToken = useSetAtom(refreshTokenAtom);
  const setVerifier = useSetAtom(verifierAtom);
  const [, setCode] = useQueryParam("code");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const clearAppContext = () => {
    clearContext();
    setCode("");
    queryClient.clear();
    setAccessToken(undefined);
    setRefreshToken(undefined);
    setVerifier(undefined);
    localStorage.clear();
    navigate("/welcome");
  };
  const { mutate } = useApiLogout({
    mutation: {
      onSuccess: () => {
        clearAppContext();
      },
      onError: () => {
        clearAppContext();
      },
    },
  });

  return () => {
    mutate();
  };
};
