import { AxiosError } from "axios";
import { useAtom } from "jotai";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { StyledLink, SubmitButton } from "@/components/Interface/Button/Button";
import { ConfirmationButtonFlexBox } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { AuthenticatorSmsConfirmForm } from "@/components/Welcome/AuthenticatorSetup/AuthenticatorSmsConfirmForm";
import { TwoFactorConfirmationCodeForm } from "@/components/Welcome/AuthenticatorSetup/TwoFactorConfirmationInput";
import { useAlert } from "@/hooks/useAlert";
import { twoFactorPhoneNumberAtom } from "@/hooks/useRequestTwoFactorPhoneNumber";
import {
  useResendActionForSmsSetup,
  useResendForSmsPhonenumberChange,
} from "@/hooks/useResendSms";
import { portalRoutes } from "@/routes/portalRoutes";
import { useValidateTwoFactorAuthentication } from "@/services/auth";
import {
  ChangeTwoFactorAuthenticationMethod,
  ValidateTwoFactorAuthenticationMethod,
} from "@/services/model";
import { AlertTypes } from "@/utils/atoms";

import { expectedAuthTypeQueryParam } from "./TwoFactorAuthStatus";

// TODO: This component is very similar to AuthenticatorSmsConfirm.tsx
/**
 * Confirmation screen for both the 2FA SMS setup and the 2FA phone number change.
 */
export const TwoFactorSmsConfirm = ({
  useResendAction,
}: {
  useResendAction:
    | typeof useResendForSmsPhonenumberChange
    | typeof useResendActionForSmsSetup;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showToast = useAlert();
  const formMethods = useForm<TwoFactorConfirmationCodeForm>({
    defaultValues: {
      code: "",
    },
  });
  const [, setLocalPhoneNumber] = useAtom(twoFactorPhoneNumberAtom);
  const { mutateAsync: validateTwoFactorAuth } =
    useValidateTwoFactorAuthentication();

  const resendAction = useResendAction();

  return (
    <FormProvider {...formMethods}>
      <form
        className="flex flex-col gap-10 text-black"
        onSubmit={formMethods.handleSubmit((data) => {
          validateTwoFactorAuth({
            params: {
              code: parseInt(data.code),
              method: ValidateTwoFactorAuthenticationMethod.SMS,
            },
          })
            .then(() => {
              setLocalPhoneNumber((data) => ({ ...data, confirmed: true }));
              showToast({
                text: t("portal:common.alerts.success"),
                type: AlertTypes.success,
              });
              const queryParams = new URLSearchParams();
              queryParams.set(
                expectedAuthTypeQueryParam,
                ChangeTwoFactorAuthenticationMethod.SMS,
              );
              navigate(portalRoutes.user.security.base + `?${queryParams}`);
            })
            .catch(
              (
                error: AxiosError<{
                  errorCode: number;
                  message: string;
                }>,
              ) => {
                if (
                  error.response?.status === 400 &&
                  error.response.data?.errorCode === 402
                ) {
                  formMethods.setError("code", {
                    type: "manual",
                    message: t(
                      "portal:authenticator.app.setup.step2.invalidCode",
                    ),
                  });
                }
              },
            );
        })}
      >
        <AuthenticatorSmsConfirmForm resendAction={resendAction} />
        <ConfirmationButtonFlexBox forceButtonWidth>
          <StyledLink className="inverted accent" to={-1 as any}>
            {t("portal:common.buttons.back")}
          </StyledLink>
          <SubmitButton
            label={t("portal:authenticator.sms.verification.finishSetup")}
            className="accent"
          />
        </ConfirmationButtonFlexBox>
      </form>
    </FormProvider>
  );
};
