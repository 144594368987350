import { QueryClientProvider } from "@tanstack/react-query";
import { FC } from "react";

import { Alert } from "./components/Interface/Alert/Alert";
import { useHandleError } from "./hooks/useHandleError";
import { useQueryClient } from "./hooks/useQueryClient";
import { Routing } from "./router/Routing";

export const Main: FC = () => {
  const handleError = useHandleError();
  const queryClient = useQueryClient(handleError);

  return (
    <QueryClientProvider client={queryClient}>
      <Alert />
      <Routing />
    </QueryClientProvider>
  );
};
