/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 112.0.0-SNAPSHOT
 */

export type CreateTransactionDatatransOrderType =
  (typeof CreateTransactionDatatransOrderType)[keyof typeof CreateTransactionDatatransOrderType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateTransactionDatatransOrderType = {
  AddContractToCustomer: "AddContractToCustomer",
  AddOptionToContract: "AddOptionToContract",
} as const;
