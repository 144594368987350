import { FieldErrors, useForm, UseFormRegister } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FCC } from "types";

import { SubmitButton } from "@/components/Interface/Button/Button";
import { RadioButton } from "@/components/Interface/FormFields/RadioButton/RadioButton";
import { OrSeparator } from "@/components/Layout/OrSeparator";
import { authenticatorRoutes } from "@/router/AuthenticatorRoutes";
import { ChangeTwoFactorAuthenticationMethod } from "@/services/model/changeTwoFactorAuthenticationMethod";

export type AuthenticatorSetupMethodsForm = {
  type: keyof typeof ChangeTwoFactorAuthenticationMethod | null;
};
export const AuthenticatorSetupForm: FCC = () => {
  const { t } = useTranslation("translation");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthenticatorSetupMethodsForm>();

  const onSubmit = (data: AuthenticatorSetupMethodsForm) => {
    // Handle form submission
    if (data.type === ChangeTwoFactorAuthenticationMethod.APP) {
      // Handle APP setup
      navigate(authenticatorRoutes.app);
    }
    if (data.type === ChangeTwoFactorAuthenticationMethod.SMS) {
      // Handle SMS setup
      navigate(authenticatorRoutes.smsSetup);
    }
    if (data.type === ChangeTwoFactorAuthenticationMethod.STANDARD) {
      // Handle LOGIN setup
      navigate("/portal/cockpit");
    }
  };

  return (
    <div className="flex gap-10 flex-col">
      <h1 className="text-primary-100">{t("2fa.title")}</h1>
      <p>
        {t("2fa.protect-your-account")}
        <br />
        {t("2fa.choose-one")}
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-secondary-100 text-xl font-semibold mb-4">
          {t("2fa.setup.subtitle")}
        </h2>
        <div className="flex flex-col gap-8">
          <AuthenticatorSetupFormElements register={register} errors={errors} />
          <div className="flex w-full">
            <div className="ml-auto">
              <SubmitButton className="accent" label={t("2fa.next")} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export const AuthenticatorSetupFormElements = ({
  register,
  errors,
}: {
  register: UseFormRegister<AuthenticatorSetupMethodsForm>;
  errors: FieldErrors<AuthenticatorSetupMethodsForm>;
}) => {
  const { t } = useTranslation("translation");
  return (
    <>
      <TwoFactorAppOption register={register} errors={errors} />

      <TwoFactorSmsOption register={register} errors={errors} />

      <OrSeparator />

      <TwoFactorStandardOption register={register} errors={errors} />
    </>
  );
};
export const TwoFactorAppOption = ({
  register,
  errors,
}: {
  register: UseFormRegister<AuthenticatorSetupMethodsForm>;
  errors: FieldErrors<AuthenticatorSetupMethodsForm>;
}) => {
  const { t } = useTranslation("translation");
  return (
    <div>
      <RadioButton<AuthenticatorSetupMethodsForm>
        register={register}
        errors={errors}
        value={ChangeTwoFactorAuthenticationMethod.APP}
        name="type"
        labelComponent={
          <p className="-mt-1 text-black text-lg font-semibold">
            <span>{t("2fa.setup.options.authenticatorApp.title")}</span>
            <span className="ml-2 text-primary-100">
              {t("2fa.setup.options.very-secure")}
            </span>
          </p>
        }
      />
      <div className="text-base text-black ml-7">
        <p>{t("2fa.setup.options.authenticatorApp.description.intro")}</p>
        <ol className="list-inside">
          <li className="list-decimal">
            {t("2fa.setup.options.authenticatorApp.description.download")}
          </li>
          <li className="list-decimal">
            {t("2fa.setup.options.authenticatorApp.description.clickNext")}
          </li>
        </ol>
      </div>
    </div>
  );
};

export const TwoFactorSmsOption = ({
  register,
  errors,
}: {
  register: UseFormRegister<AuthenticatorSetupMethodsForm>;
  errors: FieldErrors<AuthenticatorSetupMethodsForm>;
}) => {
  const { t } = useTranslation("translation");
  return (
    <div>
      <RadioButton<AuthenticatorSetupMethodsForm>
        register={register}
        errors={errors}
        value={ChangeTwoFactorAuthenticationMethod.SMS}
        name="type"
        labelComponent={
          <p className="-mt-1 text-black text-lg font-semibold">
            <span>{t("2fa.setup.options.sms.title")}</span>
            <span className="ml-2 text-primary-100">
              {t("2fa.setup.options.secure")}
            </span>
          </p>
        }
      />
      <div className="text-base text-black ml-7">
        <p>{t("2fa.setup.options.sms.description")}</p>
      </div>
    </div>
  );
};

export const TwoFactorStandardOption = ({
  register,
  errors,
}: {
  register: UseFormRegister<AuthenticatorSetupMethodsForm>;
  errors: FieldErrors<AuthenticatorSetupMethodsForm>;
}) => {
  const { t } = useTranslation("translation");
  return (
    <div>
      <RadioButton<AuthenticatorSetupMethodsForm>
        register={register}
        errors={errors}
        value={ChangeTwoFactorAuthenticationMethod.STANDARD}
        name="type"
        labelComponent={
          <p className="-mt-1 text-black text-lg font-semibold">
            <span>{t("2fa.setup.options.login.title")}</span>
            <span className="ml-2 text-primary-100">
              {t("2fa.setup.options.not-recommended")}
            </span>
          </p>
        }
      />
      <p className="text-base text-black ml-7">
        {t("2fa.setup.options.login.description")}
      </p>
    </div>
  );
};
