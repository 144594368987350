/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 112.0.0-SNAPSHOT
 */

export type GetSimListSimStatusItem =
  (typeof GetSimListSimStatusItem)[keyof typeof GetSimListSimStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSimListSimStatusItem = {
  GREEN: "GREEN",
  YELLOW: "YELLOW",
  GREY: "GREY",
  RED: "RED",
} as const;
