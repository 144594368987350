import { Provider } from "jotai/react";
import { createStore } from "jotai/vanilla";
import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { Background } from "@/components/Layout/Background/Background";
import { Footer } from "@/components/Layout/Footer/Footer";
import { WelcomeHeader } from "@/components/Welcome/WelcomeHeader/WelcomeHeader";
import { useHasTwoFactorAuth } from "@/hooks/useHasTwoFactorAuth";

export const authenticatorStore = createStore();

export const AuthenticatorLayout: FC = () => {
  const hasTwoFactorAuth = useHasTwoFactorAuth();
  if (hasTwoFactorAuth) {
    return <Navigate to="/portal/cockpit" />;
  }
  return (
    <Provider store={authenticatorStore}>
      <Background>
        <WelcomeHeader />
        <div className="flex min-h-screen mt-16 xl:mt-4">
          <div className="bg-white flex flex-col items-center justify-center w-fit max-w-4xl h-fit m-4 md:m-auto mt-14 md:mt-auto p-10">
            <Outlet />
          </div>
        </div>
        <Footer className="welcome-screen" />
      </Background>
    </Provider>
  );
};
