import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, SubmitButton } from "@/components/Interface/Button/Button";
import { ConfirmationButtonFlexBox } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import {
  AuthenticatorSetupFormElements,
  AuthenticatorSetupMethodsForm,
} from "@/components/Welcome/AuthenticatorSetup/AuthenticatorSetupForm";
import { portalRoutes } from "@/routes/portalRoutes";
import { ChangeTwoFactorAuthenticationMethod } from "@/services/model";

export const TwoFactorInitialSetupForm = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthenticatorSetupMethodsForm>();
  const navigate = useNavigate();

  const onSubmit = (data: AuthenticatorSetupMethodsForm) => {
    // Handle form submission
    if (data.type === ChangeTwoFactorAuthenticationMethod.APP) {
      // Handle APP setup
      navigate(portalRoutes.user.security.initialAppSetup);
    }
    if (data.type === ChangeTwoFactorAuthenticationMethod.SMS) {
      // Handle SMS setup
      navigate(portalRoutes.user.security.initialSmsSetup);
    }
    if (data.type === ChangeTwoFactorAuthenticationMethod.STANDARD) {
      // Handle LOGIN setup
      navigate(portalRoutes.user.security.base);
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex gap-6 flex-col text-black"
    >
      <h3 className="text-secondary-100">
        {t("portal:user-account.security.2fa.initialSetup.title")}
      </h3>
      <div>
        <p>{t("translation:2fa.protect-your-account")}</p>
        <p>{t("translation:2fa.choose-one")}</p>
      </div>
      <div className="flex flex-col gap-4">
        <h3>{t("translation:2fa.setup.subtitle")}</h3>
        <AuthenticatorSetupFormElements register={register} errors={errors} />
      </div>
      <ConfirmationButtonFlexBox forceButtonWidth>
        <Button
          type="button"
          className="accent inverted"
          onClick={() => navigate(portalRoutes.user.security.base)}
        >
          {t("portal:common.buttons.cancel")}
        </Button>
        <SubmitButton className="accent" label={t("translation:2fa.next")} />
      </ConfirmationButtonFlexBox>
    </form>
  );
};
