/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 112.0.0-SNAPSHOT
 */

export type DatatransPaymentResponseStatus =
  (typeof DatatransPaymentResponseStatus)[keyof typeof DatatransPaymentResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DatatransPaymentResponseStatus = {
  OK: "OK",
  ERROR: "ERROR",
} as const;
